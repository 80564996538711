.fade.show {
  opacity: 1;
}

.app-modal {
  opacity: 0.01;
  transition: opacity .3s ease-in;
  overflow: auto;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  outline: 0;

  &__backdrop {
    &.show {
      opacity: 0.6;
    }
  }

  img {
    max-width: 100%;
  }

  .modal-title {
    @include title-large;

    @media ($under-screen-small) {
      @include title-medium;
    }
  }

  .modal-subtitle {
    @include text;
  }

  .modal-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 18px;
    font-size: 18px;
    z-index: 1051;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    color: $color-grey-dark;

    @media ($under-screen-small) {
      padding: 12px;
    }
  }

  .alert {
    text-align: center;
  }

  .modal-medium {
    @media ($over-screen-xsmall) {
      width: 100%;
    }
    @media ($over-screen-small) {
      width: 600px;
      max-width: 600px;
    }
  }

  .modal-large {
    @media ($over-screen-small) {
      max-width: 800px;
    }
  }

  .modal-content {
    text-align: left;
    box-shadow: none;
    background-color: $color-white;
    border-radius: 4px;
    background-clip: padding-box;
    outline: none;
    box-sizing: border-box;
    position: relative;

    &--centered {
      text-align: center;
    }
  }

  .modal-header {
    background: $color-blue-background;
    padding: 30px 50px 20px 50px;
    border-radius: 4px;
    text-align: center;
    color: $color-black;
    font-size: 30px;
    line-height: 34px;
    justify-content: center;
    @media ($under-screen-small) {
      padding: 25px 50px 20px 50px;
      font-size: 20px;
      line-height: 24px;
    }
  }

  .modal-body {
    padding: 20px 50px 0;
    @media ($under-screen-small) {
      padding: 15px 20px 0;
    }

    &:last-child {
      padding-bottom: 20px;
      @media ($under-screen-small) {
        padding-bottom: 15px;
      }
    }
  }

  .modal-actions {
    text-align: center;
    &__button {
      min-width: 100px;
    }
  }

  .modal-footer {
    padding: 20px 50px 35px;
    @media ($under-screen-small) {
      padding: 15px 20px 25px;
    }
  }

  &__split-button {
    width: 48.5%;
    display: inline-block;
  }

  &__button {
    margin: 0 5px 0 0;

    @media ($under-screen-small) {
      width: 100%;
      display: block;
      margin: 0 0 10px 0;
    }
  }

  &__full-width {
    width: 100%;
  }

  &--hidden {
    display: none;
  }
}

// Used by Google Ad Manager
.white-popup {
  padding: 35px 30px;
}

.modal-button-container {
  border: 0;
}

.ios11 {
  &.modal-open {
    position: fixed;
    width: 100%;
  }
}

.modal-tips {
  padding: 35px;

  @media ($under-screen-small) {
    padding: 25px 18px;
  }

  &__title {
    @include title-medium;
    border-bottom: $grey-line;
    padding-bottom: 4px;
    margin-bottom: 15px;

    @media ($under-screen-small) {
      padding-right: 70px;
      padding-bottom: 10px;
    }
  }

  p {
    margin-bottom: 20px;

    @media ($under-screen-small) {
      margin-bottom: 10px;
    }
  }
}
