$number-of-elements: 12;
$loader-color: $color-black;

@-webkit-keyframes uil-default-anim {
  0% { opacity: 1; }
  100% { opacity: 0; }
}
@keyframes uil-default-anim {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

.hu-loader {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
  transform: scale(0.20);
  margin: auto;
}

$animation-delay: -0.5s;
$animation-delay-interval: 0.08333333333333331s;
$rotation-deg: 0;
$rotation-deg-interval: 30;

@for $i from 0 to $number-of-elements {
  .hu-loader-element-#{$i+1} {
    top: 80px;
    left: 93px;
    width: 14px;
    height: 40px;
    background: $loader-color;
    transform: rotate(#{$rotation-deg}deg) translate(0,-60px);
    border-radius: 10px;
    position: absolute;
    animation: uil-default-anim 1s linear infinite;
    animation-delay: $animation-delay;
  }
  $animation-delay: $animation-delay+$animation-delay-interval;
  $rotation-deg: $rotation-deg+$rotation-deg-interval;
}

.beacon-loader {
  position: fixed;
  left: 20px;
  bottom: 20px;
}
