.community-header {
  background-color: $color-white;
  width: 100%;
  box-shadow: $shadow-light;

  &__cover {
    position: relative;
    width: 100%;
    background-size: cover;
    background-position: center center;
    padding-top: 20%;

    max-width: 1900px;
    margin: 0 auto;

    @media (min-width: 1900px) {
      padding-top: 0;
      height: 380px;
    }

    .uploader__preview {
      padding-top: 20%;
      top: 0;
      height: 100%!important;
      width: 100%!important;

      @media (min-width: 1900px) {
        padding-top: 0;
        height: 380px;
      }

      img {
        width: 100%!important;
      }
    }

    .uploader__actions--current {
      display: none;
    }

    .uploader__actions--preview {
      bottom: initial;
    }

    &:hover {
      .uploader__actions--current {
        display: flex;
      }
    }
  }

  &__cover .uploader__actions {
    top: 0;

    &--preview {
      top: 4px;
    }

    @media ($under-screen-small) {
      top: 0;

      &--current {
        align-items: flex-end;
        justify-content: flex-end;
      }

      &--preview {
        top: unset;
        right: 0;
        bottom: 0;
        left: unset;
        transform: none;
        text-align: right;
      }
    }
  }

  .uploader__actions button {
    border: none;
    background-color: rgba(0, 0, 0, 0.4);
    color: $color-white;

    &:hover {
      background-color: rgba(0, 0, 0, 0.7);
    }

    .icon {
      color: $color-white;
    }
  }

  &__content {
    border-bottom: 1px solid $color-grey-light;
  }

  &__desc {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 15px 15px 0;
    max-width: 1200px;
    margin: 0 auto;

    &-content {
      margin-left: 160px;
      @media ($under-screen-small) {
        margin-left: 0;
        text-align: center;
      }
    }

    &-actions {
      @media ($over-screen-small) {
        margin-left: auto;
      }
    }

    @media ($under-screen-small) {
      flex-direction: column;
      padding: 15px;
    }

    @media ($under-screen-xlarge) and ($over-screen-small) {
      margin-left: 20px;
    }
  }

  &__logo {
    position: absolute;
    bottom: 15px;
    left: 10px;
    border-radius: 50%;
    box-shadow: $shadow-light;
    border: $grey-line;
    background-color: $color-white;

    @media ($under-screen-small) {
      top: -38px;
      bottom: auto;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  &__logo-img {
    width: 130px;
    height: 130px;
    display: block;
    border-radius: 50%;
    background-clip: padding-box;
    background-size: cover;
    box-sizing: border-box;
    background-color: $color-grey-light;
    border: 5px solid $color-white;

    @media ($under-screen-small) {
      border: 2px solid $color-white;
      width: 56px!important;
      height: 56px!important;
    }

    &:hover {
      .uploader__actions--current {
        display: flex;
        left: 3px;
        top: 1px;
      }
    }

    .uploader__actions--current {
      display: none;
    }

    .uploader__preview {
      border-radius: 50%;

      @media ($under-screen-small) {
        top: 0;
        left: 0;
        width: 56px!important;
        height: 56px!important;

        img {
          top: 0!important;
          left: 0!important;
          width: 56px!important;
          border-radius: 50%;
          border: 2px solid $color-white;
        }
      }
    }
  }

  &__title {
    @include title-large;
    margin: 0;

    @media ($under-screen-small) {
      margin: 5px 0;
    }
  }

  &__subtitle {
    @include text-light;
    @media ($under-screen-small) {
      margin: 5px 0;
    }
  }

  &__archived-btn {
    padding: 0;
    margin: 0;
    color: $color-text-light;
    &:hover {
      color: $color-text-link-primary-hover;
      .icon-information:before {
        color: $color-text-link-primary-hover;
      }
    }
    .icon-information:before {
      color: $color-text-link-primary;
      transition: all 0.7s ease;
      font-size: 24px;
      -webkit-font-smoothing: auto;
      -moz-osx-font-smoothing: auto;
      margin-left: 2px;
      vertical-align: middle;
    }
  }

  &__navbar {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    padding-bottom: 10px;

    @media ($under-screen-small) {
      padding: 0 10px;
    }

    @media ($under-screen-xlarge) and ($over-screen-small) {
      margin-left: 20px;
    }

    .navigation-bar__list-item a {
      margin: 0 7px;

      @media ($over-screen-small) {
        margin: 0 10px;
        line-height: 55px;
        box-sizing: border-box;
      }
      @media ($under-screen-xsmall) {
        margin: 0 3px;
      }
    }

    .dropdown-toggle {
      @media ($under-screen-small) {
        height: 35px;
        padding: 0px;
        vertical-align: baseline;
        font-size: $font-size-xs;
        line-height: 45px;
        border: none;
      }
    }
  }
}
