.community-about {
  &__container {
    @media ($under-screen-small) {
      padding: 0 15px;
      margin-bottom: 30px;
    }
  }

  &__website {
    display: inline-block;
  }

  &__website,
  &__number,
  &__support-body {
    margin-bottom: 10px;
  }

  &__title {
    @include title-medium;
    margin-bottom: 15px;

    @media ($over-screen-small) {
      font-size: 24px;
    }
  }

  &__subtitle {
    @include title-medium;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: $grey-line;
  }

  &__description {
    @include text-wide-spacing;
    white-space: pre-wrap;
    margin-bottom: 45px;
  }

  &__supporting-members-list {
    display: flex;
    flex-wrap: wrap;

    &__item {
      padding-bottom: 15px;
      width: 50%;
      min-width: 200px;

      @media ($over-screen-small) and ($under-screen-medium) {
        width: 50%;
      }
    }

    &__avatar {
      float: left;
      margin-right: 10px;
      padding-top: 5px;
    }

    &__user {
      float: left;
      width: calc(100% - 60px);
    }

    &__user-link {
      @include text-link-primary;
      overflow-wrap: break-word;
      word-wrap: break-word;
      padding-left: 4px;
      display: block;
    }

    &__see-more-link {
      @include text-link-primary;
      display: block;
      margin-top: 10px;
    }
  }

  &__support-button {
    font-size: $font-size-xs;
  }
}
