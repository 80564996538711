.community-search {
  @media ($under-screen-small) {
    padding: 0 15px;
  }

  .search-bar {
    margin-bottom: 20px;
  }

  .search-feedback {
    margin-bottom: 20px;
  }

  .search-tips.no-query {
    @media ($under-screen-small) {
      padding: 0 5px;
      margin-bottom: 20px;
    }
  }

  .search__with-results,
  .search__no-results {
    @media ($under-screen-xlarge) and ($over-screen-small) {
      margin-left: 0;
    }
  }

  .search__pagination {
    @media ($under-screen-xlarge) and ($over-screen-small) {
      margin-left: 0;
    }

    @media ($under-screen-small) {
      margin-left: 0;
    }
  }

  // search.scss imported after communitySearch.css
  &.search .search-header__navbar {
    border-bottom: $grey-line;
    margin-bottom: 20px;
    margin-left: 0;
  }

  .community-members__list {
    margin-top: 10px;
  }
}

.filter-options__sort-by .filter-section-title.hidden-title {
  visibility: hidden;
}
