$header-height: 50px;
$footer-height: 75px;
$header-footer-height: $header-height + $footer-height;
$survey-screen-padding: 15px;

.survey {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100vh;
  height: 720px;
  padding-bottom: $footer-height;

  &__screen {
    padding: $survey-screen-padding 25px;
    flex: 1;
  }

  &__title {
    @include title-medium;
  }

  &__subtitle {
    @include text-small;
    color: $color-text-light;
  }

  &__date-form-input {
    &.form-control {
      width: 130px;
      display: inline-block;
      margin-right: 5px;
      @media ($under-screen-small) {
        width: 100px;
      }

      &:last-of-type {
        margin-right: 15px;
      }
    }
  }

  &__choice-btn {
    @include text;
    border: 1px solid $color-blue;
    border-radius: 30px;
    padding: 6px 15px 8px;
    margin: 10px auto;
    width: 100%;
    text-align: left;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    background-color: $color-white;

    &:hover {
      @media ($over-screen-small) {
        background-color: lighten($color-blue, 50%);
      }
    }

    &:last-of-type {
      margin-bottom: 20px;
    }

    &--selected {
      background-color: lighten($color-blue, 50%);
      .icon {
        color: $color-blue;
      }
    }

    &-text {
      flex: 1;
    }

    &-icon {
      align-self: center;
    }
  }

  &__header {
    @include text-small;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: $header-height;
    padding: 0 20px;
    color: $color-text-light;

    a {
      color: $color-text-link-secondary;
      text-decoration: underline;
    }

    &-left, &-right {
      cursor: pointer;
    }
  }

  &__body {
    flex: 1;
    overflow: auto;
    .react-swipeable-views {
      overflow: hidden;
      height: 100%;
    }
    .react-swipeable-view-container {
      height: 100%;
      > div {
        display: flex;
      }
    }

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 10px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgba(0,0,0,.5);
      -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    height: $footer-height;
    padding: 0 20px;
    box-shadow: $shadow-light;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $color-white;
  }

  &__footer-left {
    flex: 1;
    @include text-small;
  }

  &__footer-right {
    padding-left: 20px;
  }
}
