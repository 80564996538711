.progress-bar {

  &__container {
    margin-top: 5px;
    background: $color-blue-background;
  }

  &__bar {
    background: $color-blue;
  }
}
