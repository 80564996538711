// Extra Extra small screen / small phone
$screen-xxs-min: 375px;

// Extra small screen / phone
$screen-xs-min: 480px;

// Small screen / tablet
$screen-sm-min: 768px;

// Medium screen / desktop
$screen-md-min: 992px;

// Large screen / wide desktop
$screen-lg-min: 1100px;

// Large screen / wide desktop
$screen-xl-min: 1200px;
$site-width: $screen-xl-min;

// Provide a maximum to avoid overlap when required
$screen-xxs-max: ($screen-xs-min - 1);
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-lg-max: ($screen-xl-min - 1);

// Desktop first
$under-screen-xxsmall: "max-width: " + $screen-xxs-min;
$under-screen-xsmall: "max-width: " + $screen-xxs-max;
$under-screen-small: "max-width: " + $screen-xs-max;
$under-screen-ipad: "max-width: " + $screen-sm-min;
$under-screen-medium: "max-width: " + $screen-sm-max;
$under-screen-large: "max-width: " + $screen-md-max;
$under-screen-xlarge: "max-width: " + $screen-lg-max;

// Mobile first
$over-screen-xsmall: "min-width: " + $screen-xs-min;
$over-screen-small: "min-width: " + $screen-sm-min;
$over-screen-medium: "min-width: " + $screen-md-min;
$over-screen-large: "min-width: " + $screen-lg-min;
$over-screen-xlarge: "min-width: " + $screen-xl-min;
