.react-autosuggest {
  &__container {
    position: relative;
  }

  &__input {
    width: 240px;
    height: 30px;
    padding: 10px 20px;
    border: 1px solid $color-grey-light;
    border-radius: 0px;

    &--focused {
      outline: none;
    }

    &--open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__suggestions-container {
    display: none;

    &--open {
      display: block;
      position: absolute;
      top: 45px;
      width: 100%;
      border: 1px solid $color-grey-light;
      box-sizing: border-box;
      background-color: $color-white;
      border-radius: 0px;
      z-index: 200;
    }
  }

  &__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &__suggestion {
    @include text;
    cursor: pointer;
    padding: 10px 15px;

    &--highlighted {
      background-color: $color-blue-background;
    }
  }
}


