.sitebar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: $color-white;
  line-height: 49px;
  z-index: $zindex-lift-top;
  border-bottom: $grey-line;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  // Logo in the sitebar
  .site-logo {
    display: none;
    text-align: center;

    @media ($over-screen-xlarge) {
      display: block;
    }

    a {
      display: inline-block;
    }

    &--basic {
      display: block;
    }
  }

  &__link {
    @include text-link-secondary;
    font-family: $font-family-title;
    line-height: 49px;
    display: flex;
    align-items: center;

    &--active, &:focus, &:hover, &:active {
      color: $color-black;
    }

    span[class^="icon-"] {
      margin-right: 5px;
      font-size: $font-size-m;
    }
  }

  .sitebar-actions-left,
  .sitebar-actions-right {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
  }

  .sitebar-actions-right {
    right: 15px;
  }

  .sitebar-actions-left {
    left: 15px;
  }

  .hu-badge-new {
    min-width: 23px;
    margin-left: 5px;
    padding: 2px 5px;
    text-align: center;
  }
}

// The sitebar-container sits inside the sitebar to restrict width on larger viewports
.sitebar-container {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}
