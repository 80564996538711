/*
  Cookie Control module overrides
  https://www.civicuk.com/cookie-control
*/

#ccc {
  font-family: $font-family-main !important;
}

#ccc #ccc-title, #ccc h3 {
  font-family: $font-family-title;
  font-weight: $font-weight-normal-title !important;
}

#ccc h3, #ccc p {
  line-height: $font-line-height-m !important;
}

#ccc p {
  margin: 8px 0 !important;
}


/* First modal styling */

#ccc #ccc-notify h3, #ccc #ccc-notify p {
  text-align: center !important;
}

#ccc #ccc-notify h3 {
  margin-bottom: 10px;
}

#ccc #ccc-notify p {
  opacity: 1 !important;
}

#ccc .ccc-notify-buttons {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  padding-right: 0 !important;
}

// All the buttons (1st modal & options modal)
#ccc .ccc-notify-button {
  color: $color-blue !important;
  border-color: $color-blue !important;
  border-width: 1px !important;
  font-size: 17px !important;
  line-height: $font-line-height-s !important;

  span {
    color: $color-blue !important;
  }
}

#ccc .ccc-content--dark .ccc-notify-button {
  padding: 6px 12px !important;
}

#ccc .ccc-notify-button.ccc-accept-button {
  font-weight: 400 !important;
}


/* Options modal styling */

#ccc .ccc-module--popup #ccc-content {
  background-color: $color-white !important;
  border-radius: 8px !important;
}

// H2
#ccc #ccc-title {
  font-size: $font-size-l !important;
  line-height: $font-line-height-l;

  @media ($under-screen-small) {
    font-size: $font-size-m !important;
    line-height: $font-line-height-m;
  }
}

// Statement
#ccc-statement {
  @media (max-width: 333px) {
    margin-right: 25px;
  }
}

// Fix position of svg icon for links opening in a new tab
#ccc .ccc-svg-element {
  top: 0 !important;
}

// Separators
#ccc hr {
  margin: 12px 0 !important;
  background-color: $color-grey-light !important;
}

#ccc-button-holder + hr {
  margin: 24px 0 12px !important;
}

// Remove default checkbox styles
#ccc .checkbox-toggle--checkbox.checkbox-toggle--dark {
  background-color: transparent !important;
  border: none !important;
}

// Checkbox
#ccc .checkbox-toggle {
  right: 36px !important;
}

// Fix size of label for checkbox
#ccc .checkbox-toggle--checkbox .checkbox-toggle-label {
  margin: 0;
}

#ccc .checkbox-toggle--dark .checkbox-toggle-toggle {
  background-color: transparent !important;

  &:before {
    content: 'Off';
    position: absolute;
    left: 36px;
    width: 30px;
    color: $color-text-light;
  }
}

// checkbox off
#ccc .checkbox-toggle--checkbox .checkbox-toggle-toggle {
  background-color: $color-white !important;
  border: 1px solid $color-grey !important;
  cursor: pointer;
}

// checkbox on
#ccc .checkbox-toggle--checkbox input:checked ~ .checkbox-toggle-toggle {
  background-color: $color-blue !important;
  border: 1px solid $color-blue !important;

  &::before { // Label
    content: 'On';
    color: $color-blue;
  }

  &::after { // Tick Icon
    border-color: $color-white !important;
    width: 7px !important;
    height: 12px !important;
  }
}

// Opt out of third party cookies alert box
#ccc .ccc-content--dark .ccc-alert {
  background: $color-white !important;
  border-radius: 3px;

  span {
    color: $color-black;
    fill: $color-black;
  }
}

#ccc .ccc-alert-icon {
  width: 20px !important;
  height: 20px !important;
  margin-right: 12px !important;
}

#ccc .ccc-content--dark .third-party-cookie-link {
  background: $color-white !important;
}

#ccc .ccc-alert-icon svg,
.third-party-cookie-icon svg {
  vertical-align: baseline;
}
