.results {
  @include clearfix;

  &__content {
    float: left;
    width: 60%;
    margin-right: 7%;

    @media ($under-screen-medium) {
      margin-right: 5%;
      width: 62%;
    }

    @media ($under-screen-small) {
      float: none;
      width: 100%;
      margin-right: 0;
    }
  }

  &__sidebar {
    float: left;
    width: 33%;

    @media ($under-screen-small) {
      float: none;
      width: 100%;
      padding: 0 15px;
    }
  }

  &__see-more-cta {
    text-align: center;
    padding: 10px;

    button {
      width: 100%;
    }
  }
}

.results-posts {
  border: $grey-line;
  margin: 10px 0px 30px;

  @media ($under-screen-small) {
    margin: 0 0 20px 0;
    border-right: 0;
    border-left: 0;
  }
}

.results-post {
  padding: 25px;
  border-bottom: $grey-line;

  &:last-of-type {
    border-bottom: none;
  }

  @media ($under-screen-small) {
    padding: 20px;
  }

  &__footer {
    display: flex;
    align-items: center;
    margin-top: 15px;
  }

  &__footer__avatar {
    margin-right: 10px;
  }

  &__footer__content {
    @include text-light;
    line-height: $font-line-height-s;

    a {
      @include text-link-primary;
    }
  }

  &__footer__content-title {
    @media ($under-screen-xxsmall) {
      overflow: hidden;

      * {
        float: left;
      }
    }

    a, a:hover, button {
      color: $color-grey-dark;
      margin-right: 8px;
    }

    .hu-badge {
      margin-right: 8px;
    }

    span.results-post__footer__content-title__user-link {
      margin-right: 8px;
    }
  }

  &__footer__content-separator {
    color: $color-grey-dark;
    margin-right: 8px;
  }

  &__footer__content-time {
    margin-right: 8px;
  }

  &__footer__content-replies {
    @include text;
    color: $color-grey-dark;
  }

  &__footer__content--feature-post {
    color: $color-grey-dark;
  }

  &__title {
    @include title-medium;
    margin: 0 0 10px;
    overflow-wrap: break-word; // Partial support for IE11
    word-wrap: break-word; // IE11

    @media ($over-screen-small) {
      font-size: 24px;
    }

    a {
      color: $color-black;

      &:hover {
        color: $color-black-hover;
      }
    }
  }

  &__title-badge {
    margin-left: 10px;
  }

  &__body {
    overflow-wrap: break-word; // Partial support for IE11
    word-wrap: break-word; // IE11
    @include text-wide-spacing;
    overflow: hidden;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 5px;
    max-height: $font-line-height-s-wide * 2;
    position: relative;
    display: block;

    a {
      @include text-wide-spacing;
      overflow: hidden;
      cursor: pointer;
      margin-bottom: 10px;
      margin-top: 5px;
      max-height: $font-line-height-s-wide * 2;
      position: relative;
      display: block;

      &:hover {
        color: $color-black;
      }

      &::after {
        @include fade-ellipsis($font-line-height-s-wide);
      }
    }
  }

  &__body-actions {
    margin-top: 10px;
  }
}
