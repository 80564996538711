.search {
  .search-header {
    background-color: $color-white;
    width: 100%;
    box-shadow: $shadow-light;

    &__content {
      border-bottom: 1px solid $color-grey-light;

      .search-page-container {
        min-height: 0;
        padding: 0 15px;
        margin: 0 auto 0;
        max-width: 1200px;
        width: 100vw;
      }

      @media ($under-screen-small) {
        margin-top: 0;
        padding-top: 0;
      }

      @media ($under-screen-xlarge) and ($over-screen-small) {
        margin-left: 20px;
      }

      .search-title {
        @include title-large;
        padding: 50px 0 15px 0;
        margin: 0;

        @media ($under-screen-small) {
          padding: 30px 0 15px 0;
        }
      }
    }

    &__navbar {
      height: 60px;
      max-width: 1200px;
      margin: 0 auto;

      @media ($under-screen-small) {
        padding: 0 10px;
        height: 50px;
      }

      @media ($under-screen-xlarge) and ($over-screen-small) {
        margin-left: 20px;
      }

      .navigation-bar__list-item a {
        margin: 0 6px;
        @media ($over-screen-small) {
          margin: 0 10px;
          line-height: 55px;
          box-sizing: border-box;
        }
      }
    }
  }

  .search-tips {
    border: 1px solid $color-grey-light;
    margin: 20px 0;
    padding: 20px;

    @media ($under-screen-small) {
      border: none;
    }

    &.no-query {
      margin: 20px auto 0 auto;
      border: none;
      max-width: 1200px;
      padding-bottom: 60px;

      @media ($under-screen-xlarge) and ($over-screen-small) {
        margin-left: 20px;
      }
    }

    .search-tips-title {
      @include title-medium;

      @media ($under-screen-small) {
        border-bottom: 1px solid $color-grey-light;
        padding-bottom: 10px;
      }
    }

    .search-tip-item {
      align-items: center;
      display: flex;
      padding-top: 20px;

      .search-tip-icon {
        color: $color-blue;
        border-radius: 50%;
        background: $color-grey-light;
        width: 33px;
        height: 33px;
      }

      .search-tip-text {
        flex-grow: 1;
        padding-left: 10px;
      }
    }
  }

  &__summary-row {
    display: flex;

    .results-count {
      height: 30px;
      flex-grow: 1;
      @media ($under-screen-small) {
        margin: 0 0 0 20px;
      }

      @media (max-width: 361px) {
        margin: 0 0 0 10px;
      }
    }

    .mobile-filter-modal {
      margin: 0 20px 0 5px;

      @media ($over-screen-small) {
        display: none;
      }
      @media (max-width: 361px) {
        margin: 0 10px 0 5px;
      }
    }
  }

  &__with-results {
    @media ($under-screen-xlarge) and ($over-screen-small) {
      margin-left: 20px;
    }
  }

  &__no-results {
    display: flex;
    flex-direction: column;

    @media ($under-screen-xlarge) and ($over-screen-small) {
      margin-left: 20px;
    }

    .no-results-summary * {
      padding-bottom: 10px;

      @media ($under-screen-small) {
        padding: 10px 20px;
      }
    }

    .no-results-count {
      @media ($under-screen-small) {
        border-bottom: 1px solid $color-grey-light;
        margin-bottom: 20px;
      }
    }

    .query-text {
      font-weight: bold;
      padding: 0;
    }
  }

  &__sidebar {
    @media ($under-screen-small) {
      display: none;
    }
  }

  &__pagination {
    @media ($under-screen-xlarge) and ($over-screen-small) {
      margin-left: 20px;
    }

    @media ($under-screen-small) {
      margin-left: 35px;
    }
  }

  &__communities-wrapper {
    margin-right: 20px;

    @media ($under-screen-xlarge) and ($over-screen-small) {
      margin-left: 20px;
    }

    .search__summary-row {
      margin-bottom: 5px;
    }

    .community-card-loader__list {
      @media ($under-screen-small) {
        margin-left: 20px;
      }
    }
  }

  &__communities-list {
    display: flex;
    flex-wrap: wrap;

    @media ($under-screen-small) {
      margin-left: 20px;
    }
  }
}

.search-communities {
  .search__no-results {
    @media ($over-screen-small) {
      max-width: 700px;
    }
  }
}

.filter-options {

  .modal-content {
    padding: 22px 12px 12px 12px;
  }

  .btn-wrapper {
    display: flex;

    .btn {
      flex: 1;
    }
  }

  .filter-header {
    display: flex;
    border-bottom: $grey-line;
    padding-bottom: 10px;

    .filter-title {
      @include title-medium;
      flex-grow: 1;
    }
  }

  .filter-section-title {
    @include title-small;
  }

  .radio-option {
    margin-top: 5px;

    .label-text {
      padding-left: 8px;
    }
  }
}
