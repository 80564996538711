.feedback-button {
  -webkit-appearance: none;
  background: transparent;
  border: none;
  padding: 0;
  @include text-link-primary;
}

.feedback-modal {
  padding: 35px;

  @media ($under-screen-small) {
    padding: 25px 18px;
  }

  &__title {
    @include title-medium;
    border-bottom: $grey-line;
    padding-bottom: 4px;

    @media ($under-screen-small) {
      padding-right: 70px;
      padding-bottom: 10px;
    }
  }

  p {
    margin-bottom: 20px;

    @media ($under-screen-small) {
      margin-bottom: 10px;
    }
  }
}

.search-feedback {
  margin-bottom: 50px;

  @media ($under-screen-small) {
    margin-bottom: 20px;
  }
}

.search-feedback-modal {
  &__fieldset {
    margin-bottom: 26px;
    .warning {
      color: $color-error;
      margin-bottom: 5px;
      &::before {
        content: "•";
        margin-right: 5px;
      }
    }
  }

  label {
    display: block;
  }

  &__subtitle {
    display: block;
    margin-bottom: 16px;
  }

  textarea {
    width: 100%;
    min-height: 100px;
    padding: 10px;
    border-color: $color-grey-light;

    &::placeholder {
      font-size: $font-size-xs;
    }
  }

  &__checkbox-text {
    margin-left: 10px;
  }
}

.sentiment-feedback {
  margin-bottom: 30px;

  @media ($under-screen-small) {
    margin-bottom: 20px;
  }
}

.sentiment-feedback-modal {
  &__description {
    @include text;
    background-color: $color-blue-background;
    margin-top: 15px;
    padding: 20px;

    @media ($under-screen-small) {
      padding: 10px;
    }
  }

  .sentiment-buttons {
    display: flex;
    justify-content: space-between;
  }

  .sentiment-button {
    border: 3px solid transparent;
    border-radius: 50px;
    cursor: pointer;
    padding: 4px;
    margin: 0 7px;

    @media ($under-screen-small) {
      margin: 0 3px;
    }

    &--selected {
      border-color: $color-blue;
    }

    &__img {
      @media ($under-screen-small) {
        height: 40px;
        width: 40px;
      }
    }

    &__text {
      @include sr-only;
    }
  }
}

.privacy-tips {
  &-button {
    float: left;
  }

  &-modal {
    p {
      margin-bottom: 20px;

      @media ($under-screen-small) {
        margin-bottom: 10px;
      }
    }

    ul {
      list-style: none;
      padding: 0;
    }

    li {
      padding-left: 1em;
      text-indent: -1em;
    }

    li:before {
      content: "•";
      padding-right: 10px;
      color: $color-blue;
      font-size: 1.1em;
    }
  }
}
