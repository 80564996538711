.social-button {
  border-radius: 35px;
  width: 35px;
  height: 35px;
  font-size: 1.2em;
  border: none;
  color: $color-white;
  padding: 0;
  margin-right: 8px;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  transition: opacity 0.5s ease;

  &:empty:before {
    margin-right: 1px;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  &--disabled {
    opacity: 0.5;
  }

  &--no-brand-color {
    background-color: $color-black;
    transition: all 0.5s ease;

    &:hover {
      background-color: lighten($color-black, 20%);
    }
  }

  &__tumblr {
    background-color: $color-tumblr;
  }

  &__facebook {
    background-color: $color-facebook;
  }

  &__twitter {
    background-color: $color-twitter;
  }

  &__instagram {
    background-color: $color-instagram;
  }

  &__youtube {
    background-color: $color-youtube;
  }
}