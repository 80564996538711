.home {
  h1 {
    @include title-xlarge;
    font-size: 50px;
    line-height: 58px;
    margin-bottom: 25px;
  }

  h2 {
    @include title-xlarge;
    margin-bottom: 15px;

    &.home__input-title {
      color: $color-black;
    }

    @media ($under-screen-medium) {
      font-size: $font-size-m;
      line-height: $font-line-height-m;
    }
  }

  h3 {
    @include title-medium;
    margin-top: 20px;
  }

  &__header {
    background-color: $color-black;

    p, form {
      margin-bottom: 15px;
    }
  }

  &__section {
    @include text-wide-spacing;

    &:nth-of-type(even) {
      background-color: $color-green-background;
    }

    p, form {
      margin-bottom: 15px;
    }
  }

  &__section-content {
    padding: 70px 15px;
    max-width: 1200px;
    margin: 0 auto;

    @media ($over-screen-xlarge) {
      padding: 90px 15px;
    }

    @media ($under-screen-medium) {
      padding: 55px 15px;
    }

    @media ($under-screen-small) {
      padding: 40px 15px;
      line-height: 24px;
    }

    &--split {
      display: flex;

      @media ($under-screen-medium) {
        flex-direction: column;
      }
    }
  }

  .btn-lg {
    padding: 13px 20px 14px;

    @media ($under-screen-small) {
      padding: 6px 12px 7px;
    }
  }

  &__background {
    position: relative;
  }

  &__banner {
    position: relative;
    z-index: 1000;
    padding: 90px 15px;
    max-width: 1200px;
    margin: 0 auto;

    @media ($over-screen-xlarge) {
      padding: 130px 15px;
    }

    @media ($under-screen-medium) {
      padding: 70px 15px;
    }

    @media ($under-screen-small) {
      padding: 40px 15px;
      line-height: 24px;
    }
  }

  &__background-content {
    max-width: 602px;
    color: $color-white;

    h1, a {
      color: $color-white;
    }

    a:hover {
      text-decoration: underline;
    }

    p {
      font-size: $font-size-m;
    }
  }

  &__mobile-text {
    @include title-medium;
    color: $color-white;

    @media ($over-screen-small) {
      display: none;
    }
  }

  &__presslist {
    display: flex;
    flex-wrap: wrap;
    padding: 0 15px;
    justify-content: center;
    align-items: center;
  }

  &__press {
    display: flex;
    flex-wrap: wrap;
    max-width: 190px;

    &__image {
      padding: 20px;
      width: 100%;
    }
  }

  &__input-title {
    margin-bottom: 15px;
    margin-right: 30px;
    color: $color-white;

    @media ($under-screen-medium) {
      font-size: $font-size-m;
      line-height: $font-line-height-m;
    }
  }

  &__search-word {
    min-width: 380px;
    display: inline-block;

    @media ($under-screen-medium) {
      min-width: 220px;
    }
  }

  &__features {
    display: flex;
    max-width: 1200px;

    @media ($under-screen-small) {
      flex-direction: column;
    }
  }

  &__feature {
    display: flex;
    flex-direction: column;
    padding: 15px;
    width: 100%;

    &__content {
      text-align: center;

      @media ($under-screen-small) {
        flex-direction: row;
        margin-bottom: 15px;
      }

      &__image {
        display: inline-block;
        max-width: 250px;

        @media ($under-screen-medium) {
          width: 50%;
        }
      }

      &__name {
        font-size: 19px;
        font-weight: $font-weight-bold;
        margin-bottom: 18px;

        @media ($under-screen-small) {
          margin-bottom: 5px;
        }
      }
    }
  }

  &__user-quotes {
    display: flex;
    align-items: center;

    .icon {
      color: $color-black;
      font-size: $font-size-l;
      cursor: pointer;

      @media ($under-screen-small) {
        font-size: $font-size-m;
      }
    }
  }

  &__user-quotes__content {
    padding: 0 50px;
    text-align: center;

    @media ($under-screen-small) {
      padding: 0 10px;
    }
  }

  &__user-quotes__content__quote {
    @include title-large;
    font-style: italic;
    margin-bottom: 20px;
    color: $color-black;

    @media ($under-screen-small) {
      @include title-medium;
      color: $color-black;
    }
  }

  &__user-quotes__content__subtitle {
    font-size: $font-size-m;
    color: $color-black;
  }

  &__partners, &__video {
    img {
      width: 100%;
    }
  }

  &__common-links {
    margin-top: 50px;
    margin-bottom: 20px;

    @media ($under-screen-small) {
      margin-top: 0;
    }
  }

  &__link-section {
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;

    @media ($over-screen-small) {
      height: 210px;
    }

    @media ($over-screen-medium) {
      height: 135px;
    }

    a {
      @include text-link-primary;
      line-height: $font-line-height-m;

      @media ($over-screen-small) {
        width: 33%;
      }
    }

    &--large {
      @media ($over-screen-small) {
        height: 300px;
      }
    }

    &--small {
      @media ($over-screen-small) {
        height: 500px;
      }
    }
  }

  &__link-more {
    margin-top: 20px;
  }

  &__last-30 {
    @media ($under-screen-medium) {
      text-align: center;
      flex-direction: column;
      align-items: center;
    }
  }

  &__logo {
    width: 315px;
    margin-bottom: 15px;

    @media ($under-screen-medium) {
      width: 80%;
      max-width: 315px;
    }
  }

  &__numbers {
    display: flex;
    justify-content: space-around;
    width: 100%;

    @media ($under-screen-medium) {
      flex-direction: column;
    }
  }

  &__stats {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 15px;

    @media ($under-screen-medium) {
      margin: 30px 0 0;
    }

    &-number {
      @include title-xlarge;
      font-size: 70px;
      color: $color-black;

      @media ($under-screen-medium) {
        font-size: $font-size-xl;
        line-height: $font-line-height-xl;
      }
    }

    &-subtitle {
      @include title-large;
      margin-top: 15px;
      color: $color-black;

      @media ($under-screen-medium) {
        margin-top: 5px;
      }
    }

    &-description {
      @include title-large;
      min-width: 80%;
      color: $color-black;
    }
  }

  &__section-join {
    position: relative;
    background-color: $color-black;

    &-background-img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-size: cover;
      background-position: center;
    }

    &-overlay {
      position: relative;
      background-color: rgba(0,0,0,0.25);
    }

    &-content {
      padding: 250px 15px;
      max-width: 1200px;
      margin: 0 auto;

      h2 {
        @include title-xlarge;
        margin-right: 20px;

        &.home__input-title {
          color: $color-white;
        }

        @media ($under-screen-medium) {
          font-size: $font-size-m;
          line-height: $font-line-height-m;
        }
      }

      @media ($under-screen-medium) {
        padding: 150px 15px;
      }

      &--split {
        display: flex;

        @media ($under-screen-medium) {
          flex-direction: column;
        }
      }
    }
  }
}
