.default-page-container {
  padding: 15px;
  margin: 50px auto 0;
  max-width: 1200px;
  width: 100vw;

  @media ($under-screen-small) {
    display: block;
    padding: 0;
  }
}

.full-width-page-container {
  min-height: 300px;
}

.content-container {
  @include card;
  flex: 2;
  margin: 0 7.5px 60px 7.5px;
  padding: 30px 47.5px 60px 47.5px;

  @media ($under-screen-small) {
    padding: 15px 20px;
    margin: 0;
    border-radius: 0px;
  }
}

.error-page {
  box-sizing: border-box;
  padding: 60px;
  text-align: center;
  width: 100%;

  &__title {
    @include title-large;
  }

  &__sub-title {
    @include title-medium;
  }

  &__search {
    max-width: 550px;
    margin: 30px auto 0;
    text-align: left;
  }
}
