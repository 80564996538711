.button-tag {
  @include text-small;
  display: inline-block;
  background-color: $color-button-tag;
  padding: 2px 8px 4px;
  margin-top: 5px;
  margin-right: 5px;
  text-transform: lowercase;
  border-radius: 4px;
  white-space: nowrap;

  &:hover, &:focus, &:active {
    background: $color-button-tag-hover;
    color: $color-black;
  }

  &.disabled {
    &:hover, &:focus, &:active {
      background: $color-button-tag;
    }
  }
}

.button-tags {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: inline;
  }
}
