.search-box {
  margin-top: 20px;

  &__mobile {
    padding: 0 15px;
    margin: 0;

    @media ($over-screen-small) {
      display: none;
    }

    .search-bar {
      margin-bottom: 20px;
    }
  }

  &__header {
    padding-top: 30px;
    @include title-medium;
  }
}
