.community-posts {
  &__content {
    @media ($under-screen-small) {
      margin-bottom: 30px;
    }
  }

  &__heading {
    margin-bottom: 30px;
    border-bottom: $grey-line;

    @media ($under-screen-small) {
      margin: 0 20px 30px;
    }

    h3 {
      @include title-medium;
      margin-bottom: 5px;
    }
  }

  &__filters {
    margin-bottom: 15px;

    @media ($under-screen-small) {
      margin: 0 15px 10px 15px;
    }

    &-heading {
      @include title-small;
      margin-bottom: 8px;
    }
  }
}
