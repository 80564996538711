.respond {
  position: relative;
  margin: 10px 0 20px 0;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  &__header-image {
    margin-right: 10px;
    display: flex;
  }

  &__header-action {
    @include title-small;
    width: 100%;

    .btn-none {
      padding: 0;
    }
  }

  &__text-field {
    word-wrap: break-word; // Fix for IE11 to wrap text in text input
  }

  &__buttons {
    display: flex;
    border: 1px solid $color-grey-light;
    border-top: none;
    border-radius: 0 0 4px 4px;
    margin-bottom: 10px;

    @media (max-width: 450px) {
      flex-wrap: wrap;
      padding-top: 5px;
    }
  }
}

.comment-respond {
  margin-top: 20px;
}

.post-respond {
  margin-bottom: 20px;

  &--bottom {
    margin-top: 20px;
    border: 0;
  }
}
