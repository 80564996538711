.bulleted-list__item__bullet {
  border-radius: 30px;
  height: 8px;
  width: 8px;
  margin-top: 7px;
  font-size: 20px;
  float: left;
  clear: both;
}

.bulleted-list__item__text {
  @include text;
  margin-left: 18px;
  
  a {
    @include text-link-primary;
  }
}

.bulleted-list {
  padding: 0px;
  margin-left: 15px;
  clear: both;

  &__item {
    padding: 0px 0px 10px 0px;
  }
}