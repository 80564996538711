.edit-profile {
  &__header {
    margin-bottom: 7.5px;
  }

  &__header-banner {
    height: 200px;
    background-color: $color-blue-background;
    position: relative;

    @media ($over-screen-small) {
      border-radius: $card-border-radius $card-border-radius 0 0;
      border: 1px solid $color-grey-light;
      border-bottom: 0;
    }

    &-image-container, &-image {
      height: 200px;
      background-clip: padding-box;
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;

      @media ($over-screen-small) {
        border-radius: $card-border-radius $card-border-radius 0 0;
      }

      .uploader__preview {
        max-height: 200px;
        width: 100%;
        @media ($over-screen-small) {
          border-radius: $card-border-radius $card-border-radius 0 0;
        }
      }
    }

    &-actions.uploader__actions {
      &--preview {
        top: 0;
        right: 0;
        left: inherit;
        transform: none;
        text-align: right;
      }
      @media ($under-screen-small) {
        &--current {
          align-items: flex-end;
          justify-content: flex-end;
        }

        &--preview {
          bottom: 0;
        }
      }
    }
  }

  &__title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    @media ($under-screen-small) {
      display: none;
    }
  }

  &__title {
    @include title-medium;
    color: $color-grey-dark;
    margin-bottom: 0;
  }

  &__mobile-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include title-large;
    margin-bottom: 10px;
  }

  &__button-icon {
    color: $color-blue;
  }

  &__header-avatar {
    position: absolute;
    bottom: -55px;
    left: 25px;
    border-radius: 50%;
    height: 130px;
    width: 130px;
    z-index: 100;

    @media ($under-screen-small) {
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      margin: auto;
    }

    &-image-container, &-image {
      border-radius: 50%;
      height: 130px;
      width: 130px;
      border: 5px solid $color-white;
      box-shadow: $shadow-light;
      position: relative;

      .uploader__preview {
        border-radius: 50%;
      }
    }
  }

  &__header-bio {
    padding: 10px 20px;
    background-color: $color-white;
    display: flex;

    @media ($over-screen-small) {
      border-radius: 0 0 $card-border-radius $card-border-radius;
      border: 1px solid $color-grey-light;
      border-top: 0;
    }

    &-left {
      width: 220px;
      @media ($under-screen-small) {
        width: 0;
      }
    }

    &-right {
      width: 100%;
    }

    &-bottom {
      overflow: hidden;
      margin-bottom: 5px;

      .btn-primary {
        float: right;
      }
    }

    textarea {
      margin-bottom: 0;
      overflow: auto;
    }
  }

  &__mobile-header {
    @include text-small;
    padding: 0 15px 20px;

    @media ($over-screen-small) {
      display: none;
    }
  }

  &__content {
    display: flex;
    @media ($under-screen-small) {
      padding: 0 15px;
    }
  }

  &__body {
    margin-bottom: 90px;

    @media ($over-screen-small) {
      @include card;
      padding: 20px 25px;
      flex: 2;
    }
    flex: 1;
  }

  &__side-bar {
    @include card;
    margin-left: 10px;
    padding: 25px;
    flex: 1;
    @media ($under-screen-small) {
      display: none;
    }
    height: 100%;

    p {
      @include text;
      margin-bottom: 15px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__side-bar-title {
    @include title-medium;
  }

  &__side-bar-note {
    @include text;
    border-radius: $card-border-radius;
    background-color: $color-blue-background;
    padding: 15px;
  }

  &__progress-bar {
    margin: 20px 0;

    @media ($under-screen-small) {
      margin: 10px 0 0;
    }
  }

  &__footer {
    &--mobile {
      @media ($over-screen-small) {
        display: none;
      }
      text-align: center;
      background-color: $color-blue-background;
      padding: 15px 10px;
      margin-bottom: 10px;

      p {
        @include text;
        margin-bottom: 10px;
      }
      a {
        @include text-link-primary;
      }
    }
  }

  &__interest {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    label {
      @include text-small;
      margin-left: 2px;
      color: $color-text-light;

      input {
        margin-right: 5px;
      }
    }

    .button-tag {
      @include text;
      border: none;
      font-weight: $font-weight-bold;
      transition: all 0.3s ease;
    }

    span.icon {
      padding-left: 4px;

      &::before {
        font-weight: $font-weight-bold;
      }
    }
  }

  &__field-title {
    @include title-small;
    margin: 20px 0 2px;
  }
}

.form-section {
  margin-bottom: 40px;

  &__title {
    @include title-large;
    border-bottom: $grey-line;
    padding-bottom: 2px;
    margin-bottom: 10px;

    @media ($under-screen-small) {
      font-size: $font-size-l;
      line-height: $font-line-height-l;
    }
  }

  &__title-medium {
    @include title-medium;
    margin: 30px 0 10px 0;
  }

  &__title-small {
    @include title-small;
    font-weight: $font-weight-bold;
    margin: 30px 0 10px 0;
  }

  &__subtitle {
    @include text-light;
    margin: 5px 0 20px;
  }

  &__not-interested {
    margin-top: 15px;
  }

  label {
    @include text-small;
    margin-top: 15px;
    color: $color-text-light;
  }

  input[type="checkbox"] {
    margin: 0px 5px 0px 0px;
  }

  &__field-section {
    margin-bottom: 20px;
  }

  &__field-title {
    @include title-small;
    margin: 20px 0 2px;
  }

  &__field {
    &--private {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    label {
      @include text-small;
      color: $color-text-light;
    }
  }

  &__input {
    display: flex;
    gap: 10px;
    width: 330px;
    @media ($under-screen-xsmall) {
      width: 220px;
    }

    &--month {
      flex: 2;
    }

    &--year {
      flex: 1;
    }
  }

  &__link {
    @include text-link-primary;
  }
}

.uploader__preview {
  position: absolute;
  overflow: hidden;
}

.profile-feedback {
  text-align: center;

  &__input {
    min-height: 170px;
    @media ($under-screen-small) {
      min-height: 100px;
    }
  }

  &__privacy {
    @include text-small;
    color: $color-text-light;
  }
}
