.member-tags {
  position: relative;
  height: 60px;
  overflow: hidden;

  &::after {
    @include fade-ellipsis($font-line-height-m);
    right: 50px;
  }

  .see-more {
    position: absolute;
    bottom: 0;
    right: 0;
    padding-left: 15px;
    z-index: 1;
    background-color: $color-white;
    color: $color-blue;
    line-height: $font-line-height-m;
  }
}
