.footer {
  padding: 60px 15px;
  text-align: left;
  background-color: $color-black;

  .footer-copy {
    @include text-small;
    color: $color-text-white;
  }

  h2 {
    @include title-medium;
    margin-top: 0px;
    color: $color-text-white;

    @media ($under-screen-small){
      margin-bottom: 10px;
      margin-top: 35px;
    }
  }

  p {
    margin-bottom: 8px;
  }

  a {
    @include text-link-white;
  }

  .logo {
    margin-bottom: 15px;
    width: 172px;
    height: 18px;
  }

  &__social {
    margin-top: 35px;
    min-width: 215px;
    margin-bottom: 30px;
  }

  &__block {
    @media ($under-screen-small){
      margin-top: 15px;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      align-items: center;
      @media ($under-screen-small){
        margin-top: 35px;
      }

      h2 {
        margin-bottom: 0;
        margin-top: 0;
      }

      .icon {
        color: $color-white;
        cursor: pointer;
        font-size: 13px;
        width: 21px;
      }
    }
  }

  &__hide {
    text-align: center;
    margin: 0 auto;
    text-decoration: underline;
    cursor: pointer;
    height: 25px;
    margin-top: 20px;
    width: 100px;
    color: $color-white;
  }

  &__bottom {
    background-color: $color-black-dark;

    &-content {
      padding: 30px 15px;
      display: flex;
      max-width: 1200px;
      margin: 0px auto;
      justify-content: space-between;

      @media ($under-screen-small) {
        flex-direction: column;
      }
    }
  }
}

.reduced-footer {
  display: none;

  @media ($under-screen-small) {
    display: block;
  }

  padding-top: 25px;
  height: 100px;
  width: 100%;
  position: absolute;
  text-align: center;
  background: none;

  &__show-more {
    cursor: pointer;
    text-decoration: underline;
    width: 100px;
    margin: 0 auto;
  }
}

.footer-columns {
  display: flex;
  max-width: 1200px;
  margin: 0px auto;

  @media ($under-screen-small) {
    display: block;
  }
}

.footer-col-2 {
  flex: 2;
  @media ($over-screen-small) {
    margin-right: 45px;
  }
}
