.advert {

  &__modal__1 {
    width: 1;
    height: 1;
  }

  &__modal__2 {
    width: 1;
    height: 1;
  }

  &__sidebar__1 {
    margin-bottom: 16px;
    margin: 0 auto;
    width: 300px;

    iframe {
      margin-bottom: 15px;
    }
  }

  &__sidebar__2 {
    margin-bottom: 16px;
    margin: 0 auto;
    width: 300px;

    iframe {
      margin-bottom: 15px;
    }
  }

  &__leaderboard__desktop {
    margin: 0 auto;
    max-width: 728px;
  }

  &__leaderboard__mobile {
    margin: 0 auto;
    max-width: 320px;
  }
}
