.input--text-container {
  position: relative;
  .icon {
    position: absolute;
    top: 17px;
    left: 10px;
    color: $color-grey;
    font-size: 20px;
    z-index: 100;
  }
}

.input--text {
  @include text;
  box-sizing: border-box;
  width: 100%;
  height: 38px;
  padding: 5px 12px 7px 12px;
  background-color: $color-white;
  background-image: none;
  border: 1px solid $color-grey-light;
  border-radius: 0px;
  margin: 5px 0px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-appearance: none;

  &:disabled {
    background-color: $color-grey-light;
    color: $color-grey-dark;
  }

  &.has-icon {
    padding: 7px 12px 7px 34px;
    height: 43px;
  }

  &.has-error {
    border-color: $color-error;
  }

  &::placeholder {
    @include text-light;
  }

  &__validation-warning {
    @include text-small;
    color: $color-error;
    text-align: initial;
    margin-bottom: 12px;
    margin-top: 3px;

    a {
      color: $color-error;
      text-decoration: underline;
    }
  }

  &__validation-list {
    padding-left: 23px;
    margin-bottom: 0px;
    margin-top: 0px;
  }
}

.message-editor {
  position: relative;
  height: auto;
  @include input-box;
  margin: 10px 0 0;
  padding: 0;
  border-radius: 4px 4px 0 0;

  &.empty {
    .content-editor {
      a, a:not([href]) {
        color: $color-text-link-primary;
      }
    }

    .content-editor::after {
      color: $color-grey-dark;
      content: attr(placeholder);
      pointer-events: none;
    }
  }

  &.disabled {
    pointer-events: none;
    background-color: $color-blue-background;
  }
}

textarea.input--text {
  height: auto;
}

.content-editor {
  display: block;
  outline: none;
  padding: 10px 10px 12px;

  a, a:not([href]) {
    @include text-link-primary;
  }
}

[contenteditable] { // fix for safari focus issue
  -webkit-user-select: text;
  user-select: text;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px $color-white inset;
}

.label-subtitle {
  @include text-small;
  color: $color-text-light;
}
