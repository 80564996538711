// Base colours

$color-black: #333333;
$color-black-hover: lighten( $color-black, 15% );
$color-black-dark: #191919;
$color-grey-dark: #767676; // text, icon
$color-grey-dark-hover: lighten( $color-grey-dark, 15% );
$color-grey: #B9B9B9;
$color-grey-light: #E3E3E3; // border, underline
$color-grey-extra-light: #F1EEEE;
$color-white: #FFFFFF;

// Brand colours

$color-green: #2DA46D;
$color-green-background: mix($color-green, $color-white, 10%);
$color-blue: #1671C0;
$color-blue-dark: #033250;
$color-blue-background: #F5F6FA;
$color-yellow: #F5B83D;
$color-magenta: #972C5C;
$color-pink: #e75d77;

// Alert colours

$color-success: #185436;
$color-success-background: #DFF0D8;
$color-warning: #654F2A;
$color-warning-background: #fcf8e3;
$color-error: #900E0E;
$color-error-background: #F2DEDE;

// Social media colours

$color-facebook: #4267B2;
$color-twitter: #1DA1F2;
$color-instagram: #E1306C;
$color-tumblr: #36465D;
$color-youtube: #FF0000;


// Text

$color-text-black: $color-black;
$color-text-light: $color-grey-dark;
$color-text-light-hover: $color-grey-dark-hover;
$color-text-white: $color-white;
$color-text-link-primary: $color-blue;
$color-text-link-primary-hover: darken( $color-text-link-primary, 15% );
$color-text-link-secondary: $color-grey-dark;


// Buttons

$color-button-primary: $color-blue;
$color-button-primary-hover: darken( $color-button-primary, 15% );
$color-button-primary-text: $color-white;

$color-button-secondary-border: $color-blue;
$color-button-secondary-border-hover: darken( $color-blue, 15% );
$color-button-secondary-text: $color-blue;

$color-button-tertiary-border: $color-grey-light;
$color-button-tertiary-border-hover: darken( $color-grey, 15% );

$color-button-facebook: $color-facebook;
$color-button-facebook-hover: darken( $color-facebook, 15% );

$color-button-google: $color-youtube;
$color-button-google-hover: darken( $color-youtube, 15% );

$color-button-tag: $color-green-background;
$color-button-tag-hover: darken( $color-button-tag, 15% );

$color-button-focus: rgba($color-blue, 0.25);

// Badges

$color-badge-member: $color-blue-background;
$color-badge-post: $color-yellow;
$color-badge-new: $color-yellow;

// Transparent colours

$color-white-80: rgba($color-white, 0.8);
$color-transparent: rgba($color-white, 0);
$color-shadow: rgba(51, 51, 51, 0.2);
