.myhub__container {
  padding: 15px;
}

.myhub__title {
  @include title-small;
}

.myhub__logo {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 15px;
  display: inline-block;
  vertical-align: top;
}

.myhub__list {
  list-style: none;
  padding-left: 0;
  margin: 0;
  margin-bottom: 15px;
  border-bottom: 1px solid $color-grey-light;
}

.myhub__list-item {
  border-top: 1px solid $color-grey-light;
}

.myhub__link {
  display: block;
  padding: 7px 0;
  color: $color-black;
  width: 100%;
  &:hover {
    color: $color-black;
    background: darken($color-white, 0.05);
  }
}

.myhub__link-text {
  display: inline-block;
  max-width: calc(100% - 45px);
  padding-top: 4px;
}
