.local {
  &__header {
    background: $color-blue-background;

    &__container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 15px;

      @media ($under-screen-xlarge) {
        padding: 0 15px 25px;
      }

      @media ($under-screen-small) {
        padding: 25px 15px;
      }

      p {
        margin-bottom: 20px;
        @media ($over-screen-small) {
          width: 550px;
        }
      }
    }
  }

  &__header-title {
    @include title-large;
    font-weight: $font-weight-normal;
    margin-bottom: 15px;

    @media ($over-screen-small) {
      padding-top: 25px;
    }
  }

  &__image {
    width: 360px;
    @media ($under-screen-small) {
      display: none;
    }
  }

  &__container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 25px 0 0 15px;

    @media ($under-screen-xlarge) {
      padding: 25px 15px 0 15px;
    }
  }

  &__subtitle {
    font-weight: $font-weight-bold;
    padding-bottom: 5px;
  }

  &__paragraph {
    padding-bottom: 20px;
  }

  &__input-container {
    display: flex;
    align-items: center;
  }

  &__access-code-input {
    border: 2px solid $color-grey-light;
    height: 40px;
    width: 130px;
    margin-right: 10px;
    padding: 8px;

    &.has-error {
      border-color: $color-error;
    }
  }

  &__access-code-button {
    color: $color-text-link-primary;
    padding-left: 0;

    &:hover, &:focus {
      color: $color-text-link-primary-hover;
    }
  }

  &__error-message {
    color: $color-error;
  }

  &__results {
    min-height: 500px;
  }

  &__jump-buttons {
    margin: 15px 0;
  }

  &__card-list {
    display: flex;
    flex-wrap: wrap;
  }

  &__jump-button {
    cursor: pointer;
    @include text-small;
    font-weight: 600;
    background-color: $color-blue-background;
    padding: 5px 12px;
    border-radius: 100px;
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 10px;
  }

  &__more-link {
    @include text-link-primary;
    display: block;
    margin-bottom: 35px;

    .icon {
      font-size: 8px;
      margin-left: 7px;
      vertical-align: middle;
    }
  }

  &__section-title {
    @include title-medium;
    margin-top: 20px;
  }

  &__mailto {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;    
  }
}

.local-search {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;

  &__title {
    @include text;
    font-size: 18px;
    margin-bottom: 10px;
    @media ($over-screen-small) {
      width: 300px;
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }
  }

  &__box {
    margin: 5px 0;
    @media ($over-screen-small) {
      flex: 1;
      margin: 0 10px;
    }
  }

  @media ($under-screen-small) {
    flex-direction: column;
  }

  .btn {
    margin: 0;
    @media ($under-screen-small) {
      width: 100%;
      margin-top: 10px;
    }
  }
}
