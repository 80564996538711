.modal-dialog {

  @media ($under-screen-small) {
    &.modal-signup {
      max-width: 390px;
      margin: 3px auto;
    }
  }
  @media ($over-screen-small) {
    &.modal-signup {
      width: 940px;
      max-width: none;

      @media ($under-screen-medium) {
        width: 720px;
      }
    }

    &.modal-login {
      width: 390px;
    }
  }
}

.signup-box {
  padding: 30px;

  @media ($over-screen-small) {
    padding: 0px;
  }

  &__context {
    @media ($over-screen-small) {
      display: flex;
    }
  }

  &__title {
    @include title-medium;
    margin-bottom: 10px;

    @media ($under-screen-small) {
      margin-bottom: 15px;
    }
  }

  &__brand-message {
    flex-basis: 50%;
    box-sizing: border-box;
    @media ($over-screen-small) {
      background-color: $color-blue-background;
    }

    &__content {
      @include title-small;
      width: 100%;
      height: 574px;
      display: flex;
      flex-direction: column;
      text-align: left;
      padding: 40% 60px;
      @media ($under-screen-small) {
        display: none;
      }
    }

    &__quote {
      @include text-wide-spacing;
      font-style: italic;
    }

    h2 {
      @include title-medium;
    }

    &__features {
      @include text-wide-spacing;
      font-weight: $font-weight-bold;
      text-align: left;
      color: $color-text-light;
    }
  }

  &__questions, &__questions.survey__screen {
    padding: 35px;

    @media ($under-screen-small) {
      padding: 30px 15px 15px;
    }

    p {
      margin-bottom: 10px;
    }

    &__title {
      @include title-medium;
      border-bottom: $grey-line;
      padding-bottom: 4px;
    }
  }

  &__footer {
    margin-top: 50px;

    @media ($under-screen-small) {
      margin-top: 20px;
    }

    &--without-social-buttons {
      margin-top: 20px;

      @media ($under-screen-small) {
        margin-top: 10px;
      }
    }
  }

  &__footer-link {
    margin-top: 20px;
    @media ($under-screen-small) {
      margin-top: 10px;
    }
    a {
      @include text-link-secondary;
    }
  }

  &__word-with-line {
    width: 100%;
    text-align: center;
    border-bottom: $grey-line;
    line-height: 0.1em;
    margin: 20px 0;
    font-weight: $font-weight-bold;

    @media ($under-screen-small) {
      margin-bottom: 20px;
    }

    span {
      background: $color-white;
      padding:0 10px;
    }
  }

  &__form-control {
    display: flex;
    input[type=checkbox] {
      margin-top: 5px;
      margin-right: 10px;
    }
  }

  &__tips {
    background-color: $color-blue-background;
    padding: 20px;
    margin: 20px 0;

    ul {
      padding-left: 20px;
      padding: 0;
      margin-bottom: 0;
    }

    li {
      margin: 6px 0 6px 15px;
      position: relative;
    }

    &__footer {
      margin-top: 15px;
    }
  }

  &__terms {
    padding: 25px;
    overflow: auto;

    @media ($over-screen-small) {
      padding: 35px;
    }

    p {
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__close {
      float: right;
      cursor: pointer;
      color: $color-grey;
    }

    &__content {
      h3 {
        @include title-small;
        margin-bottom: 20px;
      }
    }

    &__copyright {
      @include text-small;
      color: $color-text-light;
    }
  }

  &__highlights {
    @include title-small;
    font-weight: $font-weight-bold;
    margin-bottom: 10px;
  }

  &__link-form {
    margin: 30px 0;
  }

  &__subtitle {
    padding-bottom: 40px;

    @media ($over-screen-small) {
      padding: 0 0 70px;
    }
  }

  &__mobile-subtitle {
    margin-top: 10px;
    font-weight: $font-weight-normal;
    font-size: 17px;
  }

  &__form {
    @media ($under-screen-small) {
      &__buttons {
        margin-bottom: 15px;
      }
    }

    @media ($over-screen-small) {
      flex-basis: 50%;
      box-sizing: border-box;
      padding: 0 50px;
      border-radius: 0 4px 4px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @media ($over-screen-medium) {
      padding: 60px 80px;
    }

    &--local {
      @media ($over-screen-medium) {
        padding: 60px 70px;
      }

      p {
        text-align: left;
        margin-bottom: 25px;
      }
    }
  }

  &__local-intro {
    display: flex;
    flex-direction: column;

    p {
      text-align: left;
      margin-bottom: 15px;
    }

    @media ($over-screen-small) and ($under-screen-medium) {
      margin-top: 15px;
    }
  }

  &__form-text {
    @include text-light;
    margin: 15px 0px 20px 0px;
    text-align: center;

    a {
      @include text-link-primary;
    }
  }

  &__text--success {
    color: $color-green;
  }
}

.signup-form {
  text-align: left;

  label {
    @include text-small;
    margin-top: 10px;
    margin-bottom: 0;
    font-weight: $font-weight-bold;
  }
}
