.featured-post {
  border-bottom: 1px solid $color-grey-light;

  a {
    cursor: pointer;
    text-decoration: none;
  }

  &__header {
    @include text-small;
    border-top: 0;
    padding-bottom: 10px;
    color: $color-text-light;
    display: flex;

    @media ($under-screen-small) {
      border-bottom: 1px solid $color-grey-light;
    }
  }

  &__list {
    @media ($over-screen-small) {
      border: 1px solid $color-grey-light;
    }

    .results-post {
      @media ($under-screen-small) {
        padding: 20px 0;
        border-bottom: 1px solid $color-grey-light;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    margin-top: 10px;

    &--content {
      color: $color-grey-dark;
    }
  }

  &__skip {
    @include text-small;
    color: $color-text-light;
    background-color: transparent;
    border: none;
    margin-left: auto;
    &:hover, &:focus {
      color: $color-text-link-primary-hover;
    }
  }

  &__box {
    margin: 30px 0 30px 0px;
  }

  &__call-to-action {
    padding: 10px;
    color: $color-text-link-primary;
    cursor: pointer;
    &:hover, &:active {
      color: $color-text-link-primary-hover;
    }
  }

  &__image {
    width: 100%;
    padding-bottom: 15px;

    @media ($under-screen-xxsmall) {
      padding-bottom: 10px;
    }
  }
}
