.navigator-header__row {
  margin:0 auto;
  max-width: 1200px;
  padding: 0 20px;

  @media ($under-screen-small) {
    padding: 0 0 0 10px;
  }
}

.navigator-header__inner {
  position: relative;
  width: 100%;
  height: inherit;
  height: 200px;

  @media ($under-screen-small) {
    height: 150px;
  }
}

.navigator-header__title-bar {
  height: 90px;
  border-bottom: $grey-line;
  background: rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  @media ($under-screen-small) {
    height: 50px;
  }
}

.navigator-header__title {
  @include title-large;
  padding-top: 25px;
  padding-left: 220px;
  padding-right: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $color-text-white;

  @media ($under-screen-small) {
    @include title-small;
    padding-top: 10px;
    padding-left: 90px;
    color: $color-white;
  }
}

.navigator-header {
  position: relative;

  &__logo {
    position: absolute;
    bottom: -26px;
    left: 75px;
    border-radius:50%;
    background: $color-white;
    border: 1px solid $color-grey-light;
    z-index: 1;
    box-shadow: $shadow-default;

    @media ($under-screen-small) {
      bottom: -5px;
      left: 15px;
    }
  }

  &__logo-content {
    background: $color-white;
    width: 140px;
    height: 140px;
    line-height: 140px;

    vertical-align: middle;
    display: inline-block;
    border-radius: 50%;
    background-clip: padding-box;
    z-index: 1000;

    @media ($under-screen-small) {
      width: 70px;
      height: 70px;
    }
  }

  &__logo-img {
    width: 132px;
    height: 132px;
    display: block;
    margin: 4px 0 0 4px;
    border-radius: 50%;
    background-clip: padding-box;
    background-size: cover;
    box-sizing: border-box;

    @media ($under-screen-small) {
      margin: 3px 0 0 3px;
      width: 64px;
      height: 64px;
    }
  }
}

.navigator-header {
  width: 100%;
  position: relative;

  &__cover {
    background-size: cover;
    width: 100%;
    padding-top: 20%;

    @media ($under-screen-xlarge) {
      padding-top: 20%;
    }

    @media ($under-screen-small) {
      padding-top: 30%;
    }
  }

  &__inner {
    position: relative;
    width: 100%;
    height: inherit;
  }
}
