.navigator {
  &__title {
    @include title-large;
  }

  &__subtitle {
    padding-bottom: 15px;
  }
}


.navigator__footer {
  width: 100%;
  position: fixed;
  bottom: -1px;
  left: 0;
  background: $color-blue-dark;
  color: $color-white;
  box-shadow: $shadow-default;
  z-index: 100;

  &__inner {
    max-width: 1200px;
    padding: 15px 70px 20px 345px;
    height: inherit;
    margin: 0 auto;

    @media ($under-screen-medium) {
      padding: 15px 20px 20px 20px;
      height: inherit;
    }
  }

  &__title {
    @include title-medium;
    color: $color-text-white;
  }

  &__description {
    @include text-small;
    color: $color-text-white;
  }

  &__link {
    @include text-small;
    color: $color-text-white;
    text-decoration: underline;
    transition: all 0.5s ease;

    &:hover {
      color: darken($color-white, 15%);
      text-decoration: underline;
    }
  }

  &__image {
    position: absolute;
    left: 20px;
    right: 867px;
    top: 0;
    bottom: 51px;
    margin: auto;
    height: 150px;

    @media ($under-screen-medium) {
      display: none;
    }
  }

  &__close {

    position: absolute;
    top: 0;
    bottom: 0;
    right: 30px;
    margin: auto;
    width: 30px;
    height: 30px;
    cursor: pointer;

    .icon {
      position: absolute;
      top: 5px;
      bottom: 0;
      left: 0;
      right: 0px;
      font-size: 20px;
      display: block;
      margin: auto;
    }

    @media ($under-screen-small) {
      flex-direction: column-reverse;
      width: 30px;
      height: 30px;
      top: 5px;
      right: 8px;
      margin: inherit;

      .icon {
        padding-top: 7px;
        top: 2px;
        right: 0;
        font-size: 13px;
      }
    }
  }
}

.navigator__image-full {
  width: 100%;
  margin: 10px 0px 15px 0px;
}

.navigator__banner-footer {
  margin-top: 60px;
}

.navigator__image-half {
  width: 45%;
  margin-left: 5%;
  float: right;

  @media ($under-screen-small) {
    width: 100%;
    margin: 10px 0px 15px 0px;
    float: none;
  }
}

.navigator__section-half {
  width: 50%;
  display: inline-block;

  @media ($under-screen-small) {
    width: 100%;
    display: block;
  }
}

.navigator__container {
  display: flex;
  padding: 15px;
  margin: 20px auto 0px auto;
  max-width: 1200px;
  min-height: 300px;

  @media ($under-screen-small) {
    flex-direction: column-reverse;
    padding: 0;
  }
}

.navigator__sign-up__container {
  display: flex;
  padding: 15px;
  margin: 20px auto 0px auto;
  max-width: 1200px;
  min-height: 300px;

  @media ($under-screen-small) {
    flex-direction: column;
    padding: 0;
  }
}


.navigator__content-container {
  flex: 2;
  margin: 20px 7.5px 60px 7.5px;
  padding: 0 47.5px 60px 47.5px;

  @media ($under-screen-small) {
    padding: 0px 20px 15px 20px;
    margin: 0;
    border-radius: 0px;
  }
}

.navigator__split-container {
  flex: 1;
  margin: 20px 7.5px 60px 7.5px;
  padding: 0 47.5px 60px 47.5px;

  @media ($under-screen-small) {
    padding: 0px 20px 15px 20px;
    margin: 0;
    border-radius: 0px;
  }
}

.navigator__sidebar-container {
  flex: 1;
  margin: 0 7.5px;

  @media ($under-screen-small) {
    margin: 15px;
  }
}

.navigator__info-box {
  padding-top: 20px;
  padding-bottom: 25px;
}

.navigator__contents {
  background: $color-blue-background;
  margin-top: 30px;
  padding: 20px 20px 10px 20px;

  @media ($under-screen-small) {
    padding: 5px 10px 0px 10px;
    margin-top: 15px;
  }

  li a, ul {
    @include text;
    list-style-type: none;
  }

  ul {
    padding-left: 10px;
  }

  li {
    margin-bottom: 15px;

    @media ($under-screen-small) {
      margin-bottom: 8px;
    }
  }

  li a:hover {
   color: lighten($color-black, 30%);
  }

  &__list {
    display: inline-block;
    margin-top: 0px;
  }

  &__number {
    float: left;
    clear: both;

  }

  &__content-text {
    display: block;
    margin-left: 25px;
    text-decoration: underline;
  }
}

.navigator__contents-header {
  padding: 10px;
  @include title-medium;
}

.navigator__content {
  position: relative;

  &__subtitle {
    color: $color-grey-dark;
  }

  p {
    @include text;
    margin-bottom: 10.5px;
  }

  a {
    @include text-link-primary;
  }

  i {
    @include text;
  }

  hr {
    border-top: 1px solid $color-grey-light;
  }

  h3 {
    font-weight: 600;
    font-size: 19px;
    margin-bottom: 0px;
    margin-top: 20px;

    @media ($under-screen-small) {
      font-size: 15px;
    }
  }

  h2 {
    @include title-medium;
    padding-bottom: 3px;
    margin-bottom: 20px;
    margin-top: 60px;
    border-bottom: 1px solid $color-grey-light;

    @media ($under-screen-small) {
      margin-top: 30px;
      font-size: 18px;
      margin-bottom: 10px;
      line-height: 30px;
      padding-right: 0;
    }
  }

  quote {
    background: $color-blue-background;
    width: 100%;
    padding: 30px;
    display: block;
    font-size: 18px;
    font-style: italic;
    color: $color-green;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  hr {
    margin-top: 5px;
    margin-bottom: 15px;
  }
}

.navigator__video-container {
  margin: 20px 0px 15px 0px;
	position:relative;
	padding-bottom:56.25%;
	padding-top:30px;
	height:0;
  overflow:hidden;
}

.navigator__video-container iframe {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}

.navigator__table {
  @include text;
  margin-top: 18px;
  margin-bottom: 12px;
  text-align: left;

  td {
    padding: 10px;
  }

  th {
    @include title-small;
    background: $color-blue-dark;
    padding: 10px;
    color: $color-white;
  }

  tr:nth-child(even) {
    background: $color-blue-background;
  }
}

.navigator__sign-up {
  position: relative;
  margin-top: 20px;
  @include text;

  .gdpr {
    padding: 15px 15px 10px 15px;
    background: $color-blue-background;
    margin-bottom: 15px;
  }

  p, .indicates-required {
    font-size: 11px;
    line-height: 15px;
    margin-bottom: 10.5px;
  }

  .title {
    @include title-small;
    margin-top: 20px;
  }

  ul {
    list-style: none;
    padding-left: 15px;
    margin-top: 5px;
  }

  input {
    margin-right: 8px;
  }

  .email {
    width: 100%;
    @include input-box;
    padding: 7px;
    margin-top: 4px;
  }

  .button {
    background-color: $color-green;
    color: $color-white;
    border-radius: 4px;
    margin: 5px 5px 5px 0px;
    transition: all 0.7s ease;
    color: $color-text-white!important;
    background-color: $color-button-primary;
    border: solid 1px $color-button-primary;
    @include text;
    padding: 5px 12px 7px 12px;

    &:hover {
      cursor: pointer;
      background-color: $color-button-primary-hover;
      border: solid 1px $color-button-primary-hover;
    }
  }
}
