.pagination-container {
  margin: 20px 0;
  display: block;

  .pagination {
    margin: 20px 0;
    display: block;

    &.main-links {
      li {
        padding: 7px 12px;

        &:not(:last-child) {
          border-right: $grey-line;
        }
      }
    }

    .next-page-link {
      @media ($under-screen-xsmall) {
        display: none;
      }

      .icon {
        margin-left: 5px;
      }
    }

    li {
      display: inline;

      a,
      &.dots {
        line-height: 1.42857143;
        text-decoration: none;
        margin-left: -1px;
      }

      a {
        @include text-link-primary;
        color: $color-blue;
      }

      &.dots {
        cursor: default;
        color: $color-grey;
      }

      &.active {
        z-index: 2;
        color: $color-black;
        cursor: default;
      }
    }
  }

  .jump-links {
    margin-top: 20px;
  }

  .pagination__small {
    padding: 0;
    margin: 0;

    &.pagination__small-top {
      display: inline-block;
    }

    li {
      border-right: none;

      a {
        background-color: transparent;
        color: $color-grey-dark;
        font-size: 13px;
        border: 0;
        padding: 4px 8px 4px 8px;
      }
    }
  }

  // Responsive pagination
  .pagination li {
    display: inline-block;

    &.active {
      display: inline-block;
    }
  }
}
