.uploader {
  background-clip: padding-box;
  background-size: cover;

  &--grab {
    cursor: move;
    cursor: grab;
  }

  &--grabbing {
    cursor: grabbing;
  }
}

.uploader__actions {
  position: absolute;

  &--current {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &--preview {
    right: auto;
    bottom: -45px;
    width: 200px;
    left: 50%;
    text-align: center;
    transform: translate(-50%);
  }
}