.input--dropdown {
  @include text;
  box-sizing: border-box;
  width: 100%;
  height: 38px;
  padding: 5px 22px 7px 8px;
  background-color: $color-white;
  border: 1px solid $color-grey-light;
  border-radius: 0px;
  margin: 5px 0px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-appearance: none;
  background-image: url('https://assets.hu-production.be/aurora/img/dropdown-icon.png');
  background-size: auto 6px;
  background-repeat: no-repeat;
  background-position: right 10px bottom 14px;

  // Remove double dropdown arrow from appearing on IE11
  &::-ms-expand {
    display: none;
  }

  &.placeholder-dropdown {
    @include text-light;
  }
}

.dropdown-menu {
  padding-top: 1px;
  padding-bottom: 1px;
  box-shadow: $shadow-default;

  .dropdown-item {
    font-size: 15px;
  }
}
