.search-bar {
  display: flex;
  width: 100%;
  height: 48px;
  margin-bottom: 10px;

  .search-header__content--with-feedback & {
    margin-bottom: 15px;
  }

  &__input {
    @include text;
    -webkit-appearance: none;
    border: 1px solid $color-blue-background;
    box-shadow: none;
    padding: 6px 12px;
    background-color: $color-blue-background;
    flex-grow: 1;
    height: 100%;
    border-radius: 5px 0 0 5px;

    &::placeholder {
      color: $color-text-black;
    }
  }

  &__icon {
    border: 1px solid $color-blue-background;
    background-color: $color-blue-background;
    height: 100%;
    width: 60px;
    text-align: center;
    font-size: 25px;
    border-radius: 0 5px 5px 0;
    color: $color-grey-dark;
    padding: 0;
    margin: 0;
    cursor: pointer;

    &:disabled {
      background-color: $color-blue-background;
      border: 1px solid $color-blue-background;
      opacity: 1;
    }
  }

  &__submit-text {
    @include sr-only;
  }

  &__container {
    flex: 2;
    display: flex;
    height: 48px;

    @media ($over-screen-small) {
      max-width: 700px;
    }

    &--focused {
      .search-bar__input {
        border: 1px solid $color-blue;
      }

      .search-bar__icon {
        background-color: $color-blue;
        border: 1px solid $color-blue;
        color: $color-white;
        cursor: pointer;

        &:hover {
          color: $color-white;
        }
      }
    }

    &--error {
      border: 1px solid $color-error;
      border-radius: 5px;
    }
  }

  &__form {
    width: 100%;
    flex: 2;
  }

  &--global {
    @media ($over-screen-small) {
      width: 61%;
    }

    .search-bar__input {
      font-size: 18px;
      line-height: $font-line-height-s-wide;
      margin-bottom: 18px;
    }

    .react-autosuggest__suggestions-container {
      @media ($over-screen-small) {
        width: calc(100% - 40px);
      }
    }
  }

  .react-autosuggest__suggestions-container {
    border-radius: 4px;
    margin-top: 6px;
    text-align: left;

    &--open {
      border-color: $color-grey-light;
      box-shadow: $shadow-default;
    }
  }

  // desktop, visitors and members site bars
  &--navbar {
    width: 225px;
    margin-bottom: 0;

    .search-bar__container {
      height: 36px;
      display: inline-flex;
      flex: 1;
    }

    .search-bar__input {
      font-size: $font-size-xs;
      width: 180px;
    }

    .search-bar__icon {
      width: 42px;
      font-size: 18px;
    }

    .react-autosuggest__suggestions-container {
      width: 100%;
      margin-top: 1px;
    }
  }

  // desktop, members site bar
  &--navbar-member {
    width: 200px;
    height: 49px;

    .search-bar__input {
      width: 168px;
      padding: 6px 0 6px 8px;
    }

    .search-bar__icon {
      width: 32px;
    }
  }
}
