.archive {
  &__year {
    @include title-small;
  }

  &__month {
    @include title-small;
    display: inline-block;
    width: 56px;
    height: 40px;
    margin: 8px 4px 0 4px;
    border: $grey-line;
    color: $color-black;
    text-transform: uppercase;
    text-align: center;
    font-size: 13px;
    line-height: 40px;

    &:hover,
    &:focus {
      background-color: $color-blue-background;
    }

    @media ($under-screen-ipad) {
      width: 48px;
    }

    &--disabled {
      color: $color-black;
      background-color: $color-grey-light;
      cursor: default;

      &:hover,
      &:focus {
        color: $color-black;
        background-color: $color-grey-light;
      }
    }
  }
}
