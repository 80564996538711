.hidden-section {
  position: relative;
}

.hidden-section__expander {
  @include text-small;
  position: absolute;
  top: 16px;
  right: 0;
  color: $color-text-light;
  text-decoration: underline;
  cursor: pointer;

  @media ($under-screen-small) {
    float: right;
    position: relative;
    margin-top: -15px;
  }
}
