.youtubeVideoContainer {
  position: relative;
  display: block;
  padding-bottom: 56.25%;
  padding-top: 35px;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.youtube-beacon-trigger {
  border: 0;
  padding: 0;
  -webkit-appearance: none;
  background-color: transparent;
  @include text-link-primary;

  &--under-link {
    margin-left: 3px;
  }
}

.video-popup-wrapper {
  border-top: $grey-line;
  padding-top: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  margin: 5px 0;
  @media ($under-screen-medium) {
    flex-direction: column-reverse;
    align-items: stretch;
  }
}

.video-popup-button {
  margin-right: 10px;
  @media ($under-screen-medium) {
    margin: 5px 0;
  }
}

.video-popup-description {
  @include text-small;
  color: $color-text-light;
}
