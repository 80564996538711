.hu-badge {
  padding: 2px 7px;
  border-radius: 15px;
  @include text-small;
  font-size: 14px;

  @media ($under-screen-small) {
    font-size: 12px;
  }

  &-member {
    background-color: $color-badge-member;
  }

  &-author {
    background-color: $color-badge-member;
    color: $color-text-black;
  }

  &-new {
    background-color: $color-badge-new;
  }

  &-post {
    background-color: $color-badge-post;
    color: $color-text-black;
    text-transform: uppercase;
  }

  &-popular {
    background: $color-yellow;
    color: $color-text-black;
  }
}