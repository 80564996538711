// Do not add position:relative as it breaks scroll (scrollToPostId() in respondPost() action)
.post-response-item {
  padding-bottom: 20px;

  p {
    @include text-wide-spacing;
    overflow-wrap: break-word; // Partial support for IE11
    word-wrap: break-word; // IE11
    margin: 0 0 10.5px;

    a {
      @include text-link-primary;
    }

    &:last-child {
      margin: 0;
    }
  }
}

.top-level-comment {
  border-bottom: 1px solid $color-grey-light;
  margin-top: 30px;
}

.sub-comment {
  border-left: 1px solid $color-grey-light;
  padding-left: 25px;
  padding-bottom: 15px;
  margin-top: 15px;

  @media ($under-screen-small) {
    padding-left: 15px;
  }

  &:last-of-type {
    padding-bottom: 0;
  }
}

.full-indent {
  margin-top: 20px;
  padding-bottom: 0;
}

.post-response__deleted {
  padding-left: 10px;
  font-style: italic;
  color: $color-grey-dark;
}
