.sidebar-container {
  flex: 1;
  margin: 0 7.5px;

  @media ($under-screen-small) {
    margin: 15px;
  }
}

.sidebar__card-title {
  @include title-medium;
}

.sidebar__post-list {
  list-style: none;
  margin: 0;
  padding: 0;

  &__item {
    margin-bottom: 15px;
    overflow: hidden;
  }

  &__link, &__link:hover {
    @include title-small;
    float: left;
    display: block;
    width: calc(100% - 40px);
    padding: 6px 0 0 10px;
  }

  &__link__avatar {
    float: left;
    display: block;
  }
}

.sidebar__list {
  list-style:  none;
  margin: 12px 0 0 0;
  padding: 0;

  &__link {
    &, &:hover {
      @include title-small;
    }
  }
}

.medical-disclaimer {
  @include text;
  background-color: $color-green-background;
  border-radius: 4px;
  padding: 20px;

  p + p { 
    margin-top: 12px; 
  }
}

.sidebar__show-more-btn {
  margin-top: 10px;
  display: inline-block;
}