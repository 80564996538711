// Card
$card-border-radius: 4px;

@mixin card {
	border-radius: $card-border-radius;
	border: 1px solid $color-grey-light;
	background-color: $color-white;
}

// Input box
@mixin input-box {
	border-radius: $card-border-radius;
	border: 1px solid $color-grey-light;
	background-color: $color-white;
}

// Shadow
$shadow-default: 0 3px 6px $color-shadow;
$shadow-light: 0 0 6px $color-shadow;
$shadow-top: 0 2px 50px 0 rgba(0,0,0,.2);

// Misc
$grey-line: 1px solid $color-grey-light;
$zindex-lift-top: 1005;
$site-bar-padding: 15px;

// Fade overflowing content to white.
// Requirements: $height ~= line-height and parent to have position: relative & overflow: hidden
@mixin fade-ellipsis($height) {
  background: linear-gradient(90deg,hsla(0,0%,100%,0),$color-white 85%);
  bottom: 0;
  content: "";
  height: $height;
  pointer-events: none;
  position: absolute;
  right: 0;
  text-align: right;
  width: 40%;
}
