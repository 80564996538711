.navigation-bar {
  list-style: none;
  padding: 0;
  margin: 0;

  &__list-item {
    float: left;
    height: 50px;
    line-height: 45px;

    a {
      @include text;
      display: inline-block;
      line-height: 46px;
      padding: 0 10px;
      border-bottom:5px solid transparent;
      cursor: pointer;
      box-sizing: content-box;
      margin: 0 5px;
      vertical-align: middle;

      &.active, &:hover {
        border-bottom: 5px solid rgba(48,189,122,1);
      }

      @media ($under-screen-small) {
        padding: 0 2px;
        vertical-align: top;
      }
    }
  }

  &__more {
    line-height: 40px;
  }

  &__more-btn-group {
    display: inline-block;
    vertical-align: unset;
    margin-left: 7px;
    z-index: 100;

    .button {
      padding: 0 11px;
      height: 50px;
      border-radius: 0;
      border-bottom: 5px solid transparent;

      &:active, &:focus {
        background-color: $color-green;
        color: $color-white;
      }
    }
  }

  &--compact {
    .navigation-bar__list-item a {
      @media ($under-screen-small) {
        font-size: 15px;
        line-height: 45px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 63px;
      }
    }
  }
}
