.numbered-list {
  ol {
    list-style: none;
    margin-left: 15px;
    margin-top: 0px;
    padding-left: 0px;

    span {
      font-size: 18px;
    }

    @media ($under-screen-small) {
      margin-left: 0;
    }
  }
}

.numbered-list__item__text {
  @include text;
  margin-left: 20px;
  margin-bottom: 5px;

  a {
    @include text-link-primary;
  }
}

.numbered-list__item__number {
  float: left;
  clear: both;
  @include title-small;
}
