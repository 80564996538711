$font-static-url: 'https://d1ngqubzx882dn.cloudfront.net/static/';

/*  
  Fontello (http://fontello.com)
*/
@font-face {
  font-family: 'fontello';
  src: url($font-static-url + 'fonts/icons-02-2021/fontello.eot');
  src: url($font-static-url + 'fonts/icons-02-2021/fontello.eot?#iefix') format('embedded-opentype'),
       url($font-static-url + 'fonts/icons-02-2021/fontello.woff2') format('woff2'),
       url($font-static-url + 'fonts/icons-02-2021/fontello.woff') format('woff'),
       url($font-static-url + 'fonts/icons-02-2021/fontello.ttf') format('truetype'),
       url($font-static-url + 'fonts/icons-02-2021/fontello.svg#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url($font-static-url + 'fonts/new-icons-clean/fontello.svg#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  font-size: 1em;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .5em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap 
  line-height: 1em; */
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

// In hovered anchors we don't want the underline on icons
[class^="icon-"]:hover:before, [class*=" icon-"]:hover:before {
  text-decoration: none;
}

// Icons which are meant to be on their own - we should increase hit area for these as they may be small
.solo-icon {
  vertical-align: middle;

  &:before {
    width: 2em;
    line-height: 2em;
  }
}

// Coloured icons
.icon--success:before {
  color: $color-blue;
}

.icon--danger:before {
  color: $color-blue;
}

// Responsive buttons
// ----------------

// Responsive buttons will have hidden text and simply show an icon on smaller viewports

// @media ($under-screen-small) {
//   [class^="icon-"].icon-responsive:before,
//   [class*=" icon-"].icon-responsive:before {
//     margin-right: 0;
//   }
// }

// Empty icons don't need a margin (just for display purposes not prepending text)
[class^="icon-"]:empty:before, [class*=" icon-"]:empty:before, .icon:empty, .icon-empty:before {
  margin-right: 0;
}

// Sub icons can be a little less obvious
.sub-icon {
  opacity: 0.75;
}

.icon-bold:before { content: '\e800'; } /* '' */
.icon-italic:before { content: '\e801'; } /* '' */
.icon-mail-1:before { content: '\e802'; } /* '' */
.icon-bookmark:before { content: '\e803'; } /* '' */
.icon-zoom-in:before { content: '\e804'; } /* '' */
.icon-up-open:before { content: '\f005'; } /* '' */
.icon-down-open:before { content: '\f004'; } /* '' */
.icon-right-open:before { content: '\f006'; } /* '' */
.icon-left-open:before { content: '\f007'; } /* '' */
.icon-close:before { content: '\e8a7'; } /* '' */
.icon-cancel-5:before { content: '\e8a8'; } /* '' */
.icon-cross-2:before { content: '\e8a9'; } /* '' */
.icon-plus:before { content: '\e8b1'; } /* '' */
.icon-plus-2:before { content: '\e8b2'; } /* '' */
.icon-tick-2:before { content: '\e8b3'; } /* '' */
.icon-ok:before { content: '\e8b4'; } /* '' */
.icon-search:before { content: '\e8b5'; } /* '' */
.icon-search-1:before { content: '\e8b6'; } /* '' */
.icon-mail:before { content: '\e8b7'; } /* '' */
.icon-pencil:before { content: '\e8b8'; } /* '' */
.icon-pencil-1:before { content: '\e8b9'; } /* '' */
.icon-home-clear:before { content: '\e8c1'; } /* '' */
.icon-home-filled:before { content: '\e8c2'; } /* '' */
.icon-communities:before { content: '\e8c3'; } /* '' */
.icon-communities-solid:before { content: '\e8c4'; } /* '' */
.icon-messages:before { content: '\e8c5'; } /* '' */
.icon-messages-solid:before { content: '\e8c6'; } /* '' */
.icon-bell-2:before { content: '\e8c7'; } /* '' */
.icon-bell-2-solid:before { content: '\e8c8'; } /* '' */
.icon-thumbs-up:before { content: '\e8c9'; } /* '' */
.icon-thumbs-up-filled:before { content: '\e8d1'; } /* '' */
.icon-like-outline:before { content: '\e8d2'; } /* '' */
.icon-like-filled:before { content: '\e8d3'; } /* '' */
.icon-paper-plane:before { content: '\e8d4'; } /* '' */
.icon-cog:before { content: '\e8d5'; } /* '' */
.icon-settings:before { content: '\e8d6'; } /* '' */
.icon-information:before { content: '\e8d7'; } /* '' */
.icon-help:before { content: '\e8d8'; } /* '' */
.icon-picture:before { content: '\e8d9'; } /* '' */
.icon-newsfeed:before { content: '\e8e1'; } /* '' */
.icon-people:before { content: '\e8e2'; } /* '' */
.icon-profile-1:before { content: '\e8e3'; } /* '' */
.icon-alert:before { content: '\e8e4'; } /* '' */
.icon-in-reply:before { content: '\e8e5'; } /* '' */
.icon-lock:before { content: '\e8e6'; } /* '' */
.icon-link-ext:before { content: '\e8e7'; } /* '' */
.icon-logout:before { content: '\e8e8'; } /* '' */
.icon-facebook:before { content: '\e8f1'; } /* '' */
.icon-twitter:before { content: '\e8f2'; } /* '' */
.icon-youtube:before { content: '\e8f3'; } /* '' */
.icon-instagram:before { content: '\e8f4'; } /* '' */
.icon-bookmark-empty:before { content: '\f097'; } /* '' */
.icon-underline:before { content: '\f0cd'; } /* '' */
.icon-smile:before { content: '\f118'; } /* '' */
.icon-at:before { content: '\f1fa'; } /* '' */
