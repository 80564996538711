.alert {
  /* [RETENTION] Custom alert for first post */
  &.alert-first-write-post {
    background: $color-green;
    color: $color-white;

    &.first-write-post-link {
      text-decoration: underline;

      &:hover {
        text-decoration: underline;
      }
    }

    a {
      color: $color-white;
      font-weight: normal;
      text-decoration: underline;

      &:hover {
        text-decoration: underline;
      }
    }

    .close {
      color: $color-white;
      opacity: 1;
      border-width: 0;
      border-bottom-width: 2px;
      padding-bottom: 5px;
      font-weight: $font-weight-normal;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .close {
    @media ($under-screen-small) {
      position: absolute;
      right: 0;
      padding: 10px 15px 15px 15px;
      top: 5px;
    }
  }
}

.site-alert {
  position: fixed;
  right: 0;
  left: 0;
  top: 50px;
  z-index: $zindex-lift-top * 2;
  margin: 0;
  border-width: 0;
  border-bottom-width: 1px;
  padding: 18px;
  text-align: center;

  .alert-container {
    @media ($over-screen-large) {
      margin: 0 auto;
      max-width: $site-width;
      padding: 0 $site-bar-padding;
      text-align: center;
    }
  }

  .alert-close {
    border-width: 0;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    background: none;
    font-size: 20px;
    padding-left: 20px;
    color: inherit;
    font-weight: bolder;
  }
}

.local-alert {
  top: 0;
}

.alert-success {
  background-color: $color-success-background;
  color: $color-success;

  a {
    color: $color-success;
  }
}

.alert-danger {
  background-color: $color-error-background;
  color: $color-error;

  a {
    color: $color-error;
  }
}

.alert-warning {
  background-color: $color-warning-background;
  color: $color-warning;

  a {
    color: $color-warning;
  }
}
