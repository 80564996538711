.mentions {
  position: absolute;
  z-index: 1001;
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: $color-white;
  color: $color-black;
  font-size: 15px;
  border: 1px solid $color-grey-light;
  border-radius: 3px;
  box-shadow: 0px 6px 9px $color-grey-light;

  li {
    cursor: pointer;
    padding: 5px 10px;
    width: 100%;
    border-bottom: 1px solid $color-grey-light;
    display: flex;
    background-color: $color-white;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: fade-out($color-blue, 0.4);
    }

    &.mention-selected {
      background-color: fade-out($color-blue, 0.4);
    }

    img {
      height: 25px;
      width: 25px;
      border-radius: 50%;
    }

    div {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 10px;
    }
  }

  &.mentions-error {
    border: 1px solid $color-error;
  }
}
