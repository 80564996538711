.sitebar-dropdown {
  opacity: 0;
  display: block;
  visibility: hidden;
  transition: opacity 0.1s ease-out, visibility 0s 0.1s;
  background: $color-white;
  margin-bottom: 0;
  box-shadow: $shadow-default;
  overflow: auto;
  border: $grey-line;
  position: absolute;

  @media ($under-screen-small) {
    margin-left: -10px;
  }

  &--open {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.1s ease-out, visibility 0s 0s;
    z-index: 2010;
  }

  a.sitebar-dropdown__link {
    @include text-link-primary;
    padding: 15px 15px 10px 15px;
    display: block;
    color: $color-blue !important;
  }

  .divider {
    @include title-small;
    margin: 10px 15px 0px 15px;
    padding-bottom: 2px;
    border-bottom: $grey-line;
  }

  ul {
    padding-left: 0px;
    margin-top: 5px;
    margin-bottom: 10.5px;
  }

  li {
    display: block;
    &:hover {
      background-color: $color-blue-background;
    }
  }

  a, button {
    @include text;
    color: $color-black !important;
    padding: 7.5px 15px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: left;
    img {
      margin-right: 10px;
    }
  }

  a.sitebar-dropdown__item--wrap {
    white-space: normal;
  }
}
