.redirect {
  &__container {
    height: 420px;
    padding: 0;
    margin: 30px 15px;
    display: flex;
    flex-direction: column;

    @media (min-width: 700px){
      align-items: center;
    }
  }

  &__body {
    width: auto;
    padding: 30px;
    text-align: center;
    border: 1px solid #e3e3e3;
    max-width: 700px;
  }

  &__header {
    margin-top: 35px;
    font {
      font-size: 30px;
    }
  }

  &__message {
    margin-top: 35px;
    word-wrap: break-word;
    font {
      font-size: large;
    }
  }

  &__img {
    width: 30%;
    min-width: 220px;
  }
}
