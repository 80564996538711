/* Variables overrides */

// Brand Colors
$green: $color-green;
$white: $color-white;

$theme-colors: (primary: $color-blue, secondary: $white);

// Headings
$headings-margin-bottom: 0.83em;

// Paragraphs
$paragraph-margin-bottom: 0;

// Links
$link-color: $color-blue;
$link-hover-decoration: none;

// Dropdown
$dropdown-spacer: 4px;
$dropdown-border-color: $color-grey;
$dropdown-item-padding-x: 1.1rem;


/* Include parts of Bootstrap */

// Required
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Custom
@import "~bootstrap/scss/reboot"; // normalization
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown"; // reactstrap
@import "~bootstrap/scss/button-group"; // reactstrap
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/modal"; // reactstrap
@import "~bootstrap/scss/utilities/_screenreaders";
@import "~bootstrap/scss/carousel"; // reactstrap
@import "~bootstrap/scss/tooltip"; // reactstrap
