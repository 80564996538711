.community-member-card {
  &__inner {
    min-height: 224px;
    height: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    border: 1px solid $color-grey-light;
    box-shadow: none;

    @media ($under-screen-xsmall) {
      min-height: 0;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
  }

  &__avatar {
    margin-right: 10px;

    img {
      width: 40px;
      height: 40px;
    }
  }

  &__username {
    flex-grow: 2;
  }

  &__user-link {
    @include title-small;
    display: block;
    word-break: break-word;
  }

  &__badge {
    display: inline-block;
    margin-top: 4px;
  }

  &__btn-dropdown {
    .dropdown-toggle {
      padding-left: 4px;
      padding-right: 10px;
    }
    .dropdown-toggle::after {
      content: '\e8d5'; // icon-cog
    }
  }

  &__meta {
    @include text-small;
    min-height: $font-line-height-xs;
    margin-bottom: 14px;
    color: $color-text-light;
  }

  &__meta span:not(:last-child)::after {
    content: "•";
    color: $color-grey-light;
    margin: 0 4px;
  }

  &__bio {
    @include text;
    position: relative;
    height: $font-line-height-s * 2;
    margin-bottom: 14px;
    overflow: hidden;

    &::after {
      @include fade-ellipsis($font-line-height-s);
    }
  }
}
