.base-card {
  padding: 0 0 36px;

  @media ($over-screen-medium) {
    width: 33.333%;
    padding-right: 24px;
    padding-left: 0;

    &:nth-child(3n+2) {
      padding-right: 12px;
      padding-left: 12px;
    }

    &:nth-child(3n+3) {
      padding-right: 0;
      padding-left: 24px;
    }
  }

  @media ($under-screen-medium) and ($over-screen-xsmall) {
    width: 50%;
    padding-right: 13px;
    padding-left: 0;

    &:nth-child(2n+2) {
      padding-right: 0;
      padding-left: 13px;
    }
  }

  @media ($under-screen-xsmall) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }

  &__loader {
    height: 100%;
  }

  &__inner {
    box-shadow: $shadow-default;
  }

  &__header {
    width: 100%;
    background-size: cover;
    background-position: 50%;
    cursor: pointer;
    height: 140px;

    @media ($under-screen-xsmall) {
      height: 100px;
    }
  }

  &__content {
    padding: 20px 25px;
    height: 210px;
    position: relative;
    overflow: hidden;

    @media ($under-screen-xsmall) {
      height: 200px;
      padding: 15px 25px;
    }
  }

  &__title {
    @include title-medium;
    margin-bottom: 3px;
    cursor: pointer;
    
    // all cards except discover cards
    a {
      @include title-medium;
      position: relative;
      display: block;
      overflow: hidden;
      max-height: $font-line-height-m * 2;
    }
  }

  &__title--multiline {
    // Only for communities search and discover cards
    a::after {
      @include fade-ellipsis($font-line-height-m);
    }
  }

  &__subtitle {
    margin-bottom: 4px;

    a {
      @include text-small;
      color: $color-text-light;
    }

    @media ($under-screen-xsmall) {
      margin-bottom: 0;
    }
  }

  &__body {
    @include text;
    cursor: pointer;
    margin-top: 5px;
    position: relative;
    overflow: hidden;
    max-height: $font-line-height-s * 3;

    &::after {
      @include fade-ellipsis($font-line-height-s);
    }

    a {
      @include text;
      display: block;
    }
  }

  &__cta {
    display: block;
    @include text-link-primary;
    position: relative;
    line-height: $font-line-height-s-wide;
  }

  &__footer {
    position: absolute;
    background-color: $color-white;
    left: 25px;
    right: 25px;
    bottom: 0;
  }
}

.base-card-wider {
  width: 50%;
  padding: 0 13px 36px 0;

  @media ($over-screen-xsmall) {
    &:nth-child(2n+2) {
      padding-right: 0;
      padding-left: 13px;
    }
  }

  @media ($under-screen-xsmall) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
}

.discover-card {
  &__badge-wrapper {
    margin-bottom: 11px;
  }

  .base-card__inner {
    height: 350px;

    @media ($under-screen-xsmall) {
      height: 300px;
    }
  }

  .base-card__title {
    max-height: $font-line-height-m * 2;
    overflow: hidden;
  }

  &__badge {
    @include text-small;
    font-weight: 600;
    background-color: $color-blue-background;
    padding: 5px 12px;
    border-radius: 100px;
    display: inline-block;
  }

  .base-card__footer {
    padding-bottom: 22px;

    @media ($under-screen-xsmall) {
      padding-bottom: 17px;
    }
  }
}

.community-card {
  &__banner {
    position: relative;
  }

  &__inner {
    height: 400px;

    @media ($under-screen-xsmall) {
      height: auto;
    }
  }

  &__content {
    height: 258px;

    @media ($under-screen-xsmall) {
      height: 236px;
    }
  }

  &__logo {
    position: absolute;
    bottom: -10px;
    left: 20px;
    border-radius: 50%;
    box-shadow: $shadow-light;
    border: $grey-line;
    background-color: $color-white;

    @media ($under-screen-small) {
      bottom: -5px;
    }
  }

  &__logo-img {
    width: 80px;
    height: 80px;
    display: block;
    border-radius: 50%;
    background-clip: padding-box;
    background-size: cover;
    box-sizing: border-box;
    background-color: $color-grey-light;

    @media ($under-screen-small) {
      width: 60px;
      height: 60px;
    }
  }

  &__follow-button {
    margin-top: 16px;

    @media ($under-screen-xsmall) {
      margin-top: 8px;
    }
  }

  &__footer {
    bottom: 23px;

    @media ($under-screen-xsmall) {
      bottom: 18px;
    }
  }
}
