.verification-bar {
  position: relative;
  top: 50px;
  min-height: 52px;
  width: 100%;
  @include title-small;
  line-height: 22px;
  color: $color-white;
  background: $color-magenta;
  z-index: 1000;
  height: auto;

  &__inner {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;

    @media ($under-screen-medium) {
      flex-direction: column;
    }
  }

  .icon-information {
    cursor: pointer;
    font-size: 25px;
    margin-left: 6px;
    vertical-align: middle;
    color: $color-white;
    text-decoration: none;

    &:hover {
      color: darken($color-white, 15%);
    }
  }
}

.verification-bar__information-modal {
  p {
    margin-bottom: 10.5px;
  }
}

.verification-bar__actions {
  margin-left: auto;
  padding-top: 4px;

  @media ($under-screen-medium) {
    margin: 0;
    padding: 4px 15px 15px 15px;
  }

  .btn {
    font-size: 15px;
    line-height: 22px;
  }
}

.verification-bar__copy {
  padding: 15px 15px 0 15px;

  @media ($under-screen-small) {
    margin: 0;
  }
}
