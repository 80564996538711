$font-family-main: 'Lato', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
// Use these font weight sizes for Lato
$font-weight-normal: 400;
$font-weight-bold: 600;

$font-family-title: 'museo-sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
// Use these font weight sizes for Museo Sans
$font-weight-normal-title: 500;
$font-weight-bold-title: 700;


// Rules for font styles
$font-size-xs: 15px;
$font-line-height-xs: 19px;

$font-size-s: 17px;
$font-line-height-s: 21px;
$font-line-height-s-wide: 26px;

$font-size-m: 21px;
$font-line-height-m: 26px;

$font-size-l: 30px;
$font-line-height-l: 38px;

$font-size-xl: 42px;
$font-line-height-xl: 48px;


// BODY

@mixin text {
  font-family: $font-family-main;
  font-size: $font-size-s;
  line-height: $font-line-height-s;
  font-weight: $font-weight-normal;
  color: $color-text-black;
  text-decoration: none;
}

// For posts, replies, program body text
@mixin text-wide-spacing {
  @include text;
  line-height: $font-line-height-s-wide;
}

@mixin text-light {
  @include text;
  color: $color-text-light;
}

@mixin text-small {
  font-family: $font-family-main;
  font-size: $font-size-xs;
  line-height: $font-line-height-xs;
  font-weight: $font-weight-normal;
  color: $color-text-black;
  text-decoration: none;
}


// TITLES

@mixin title-xsmall {
  font-family: $font-family-title;
  font-size: 13px;
  line-height: 16px;
  font-style: normal;
  font-weight: $font-weight-bold;
  color: $color-text-black;
  text-decoration: none;
  font-display: swap;
}

@mixin title-small {
  font-family: $font-family-title;
  font-size: $font-size-s;
  line-height: $font-line-height-s;
  font-style: normal;
  font-weight: $font-weight-normal-title;
  color: $color-text-black;
  text-decoration: none;
  font-display: swap;
}

@mixin title-medium {
  font-family: $font-family-title;
  font-size: $font-size-m;
  line-height: $font-line-height-m;
  font-style: normal;
  font-weight: $font-weight-normal-title;
  color: $color-text-black;
  text-decoration: none;
  font-display: swap;
}

@mixin title-large {
  font-family: $font-family-title;
  font-size: $font-size-l;
  line-height: $font-line-height-l;
  font-style: normal;
  font-weight: $font-weight-normal-title;
  color: $color-text-black;
  text-decoration: none;
  font-display: swap;

  @media ($under-screen-small) {
    font-size: $font-size-m;
    line-height: $font-line-height-m;
  }
}

@mixin title-xlarge {
  font-family: $font-family-title;
  font-size: $font-size-xl;
  line-height: $font-line-height-xl;
  font-style: normal;
  font-weight: $font-weight-normal-title;
  color: $color-text-black;
  text-decoration: none;
  font-display: swap;

  @media ($under-screen-small) {
    font-size: $font-size-m;
    line-height: $font-line-height-m;
  }
}


// LINKS

@mixin text-link-primary-small {
  @include text-small;
  color: $color-text-link-primary;
  transition: all 0.5s ease;

  &:hover {
    color: $color-text-link-primary-hover;
    cursor: pointer;
  }
}

@mixin text-link-primary {
  @include text;
  color: $color-text-link-primary;
  transition: all 0.5s ease;

  &:hover {
    color: $color-text-link-primary-hover;
    cursor: pointer;
  }
}

@mixin text-link-secondary {
  @include text;
  color: $color-text-link-secondary;
  transition: all 0.5s ease;

  &:hover {
    color: $color-grey-dark-hover;
    cursor: pointer;
  }
}

@mixin text-link-white {
  @include text;
  color: $color-text-white;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
