.post-container {
  padding-top: 15px;

  @media ($under-screen-small) {
    padding: 30px 15px 15px;
    margin-bottom: 20px;
  }

  .btn-actions {
    color: $color-text-light;
    background-color: $color-white;
    padding: 0;
    margin-top: 0;

    &:hover, &:focus {
      color: $color-black-hover;
    }
  }
}

.post-image {
  max-width: 100%;
  margin-bottom: 30px;
}

.post-body {
  @include text-wide-spacing;
  margin-bottom: 40px;
  overflow-wrap: break-word; // Partial support for IE11
  word-wrap: break-word; // IE11

  a {
    @include text-link-primary;
  }

  & p {
    margin-bottom: 15px;
  }
}

.post-tags {
  margin-bottom: 40px;

  @media ($under-screen-small) {
    margin-bottom: 20px;
  }
}

.last-edited-by {
  margin-bottom: 30px;

  @media ($under-screen-small) {
    margin-bottom: 20px;
  }
}

.post-tags__read-more-about {
  @include text-small;
  color: $color-text-light;
  font-weight: 600;
  margin-bottom: 10px;
}

.post-actions {
  line-height: 35px;
  padding: 12px 0;
  border-top: 1px solid $color-grey-light;
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  @media ($under-screen-medium) {
    align-items: flex-start;
    border-bottom: none;
    padding: 5px 0;
  }

  .btn {
    margin-right: 10px;

    @media ($under-screen-xsmall) {
      margin: 15px 9px 0 0;
    }
  }

  .dropdown-menu {
    @media ($under-screen-xsmall) {
      transform: translate3d(-100px, 24px, 0px);
    }
  }

  .post-action {
    min-width: 80px;

    &:disabled {
      &, &:hover {
        background: $color-grey-light;
        border: 1px solid $color-grey-light;
        color: $color-black;
        pointer-events: none;
      }
    }

    &--like {
      min-width: 95px;

      @media ($under-screen-medium) {
        min-width: 86px;
      }
    }
  }

  .btn-actions {
    margin-bottom: 0;
  }
}

.post-actions-social {
  @media ($under-screen-medium) {
    margin-right: 5px;
  }
}

.post-actions-social-icon {
  &, &:hover {
    display: inline-block;
    font-size: 20px;
    vertical-align: middle;
    margin-left: 30px;
    color: $color-grey-dark;
  }

  @media ($under-screen-medium) {
    &:first-of-type {
      margin-left: 0;
    }
  }

  @media ($over-screen-medium) {
    &:last-of-type {
      margin-right: 15px;
    }
  }
}

.icon-facebook-circled:before {
  content: "f";
  color: $color-facebook;
  font-family: fontello;
  font-style: normal;
  font-weight: 400;
  speak: none;
  font-size: 1em;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .5em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
}

.icon-twitter-circled:before {
  content: "t";
  color: $color-facebook;
  font-family: fontello;
  font-style: normal;
  font-weight: 400;
  speak: none;
  font-size: 1em;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .5em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
}

.last-edited-by {
  @include text-small;
  color: $color-text-light;

  a {
    @include text-link-primary-small;
  }
}

.post-media-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  a {
    @include text-link-primary;
  }

  button {
    margin-right: 10px;

    @media ($under-screen-medium) {
      margin-right: 5px;
    }
  }
  .dropdown-toggle {
    margin-bottom: 0;
  }
}

.post-sidebar {
  padding-top: 15px;
}

