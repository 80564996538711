.community-members {
  &__search-bar {
    width: calc(60% + 12px);

    @media ($under-screen-small) {
      width: 100%;
    }

    .search-bar {
      margin-bottom: 25px;
    }

    .search-bar__container {
      max-width: none;
    }
  }

  &__filters {
    margin-bottom: 20px;

    &-heading {
      @include title-small;
      margin-bottom: 8px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__empty {
    margin: 30px 0;

    &-heading {
      @include title-medium;
      margin-bottom: 25px;
    }

    &-more-link {
      padding: 0;
      margin: 0;
      vertical-align: initial;
      color: $color-text-link-primary;

      &:hover {
        color: $color-text-link-primary-hover;
      }
    }
  }
}
