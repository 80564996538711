.floating-box {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $color-blue-dark;
  width: 100%;
  margin: auto;
  box-shadow: $shadow-top;
  z-index: 1049;
}

.floating-box-sign-up {
  color: $color-white;
  padding: 20px 20px 26px;
  text-align: center;

  @media ($under-screen-small) {
    padding: 10px 10px 13px;
  }

  @media ($under-screen-xsmall) {
    text-align: left;
  }

  &__title {
    @include title-medium;
    color: $color-white;

    @media ($under-screen-small) {
      @include title-small;
      color: $color-white;
    }

    @media ($under-screen-xsmall) {
      margin-left: 2px;
    }
  }

  &__content {
    @include text;
    color: $color-white;

    @media ($under-screen-medium) {
      @include text-small;
      color: $color-white;
    }

    &__form {
      margin-top: 15px;

      @media ($under-screen-medium) {
        margin-top: 5px;
      }
    }
  }

  &__form-input {
    @include text-light;
    width: 350px;
    height: 53px;
    display: inline-block;
    margin-right: 10px;
    padding: 11px 20px 13px 20px;

    @media ($under-screen-small) {
      padding: 5px 12px 7px 12px;
      height: 40px;
    }

    @media ($under-screen-xsmall) {
      width: 70%;
      min-width: 218px;
      margin-right: 8px;
    }
  }

  &__button {
    @media ($under-screen-small) {
      margin: -2px 0px 0px 0px;
      padding: 8px 20px 9px 20px;
    }
  }
}

.floating-box-sign-up__dismiss {
  display: flex;
  font-size: 17px;
  float: right;
  color: $color-grey-dark;

  &:hover {
    color: $color-black;
    text-decoration: none;
  }
}
