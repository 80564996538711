.btn {
  transition: all 0.7s ease;
  margin: 5px 5px 5px 0px;
  padding: 6px 12px;
  @include text;

  &-primary {
    color: $color-button-primary-text;

    &:disabled {
      &,
      &:hover {
        background: $color-grey-light;
        border: 1px solid $color-grey-light;
        color: $color-black;
      }
    }
  }

  &-secondary {
    color: $color-button-secondary-text;
    border: solid 1px $color-button-secondary-border;

    &.disabled,
    &:disabled {
      background: $color-grey-light;
      border: 1px solid $color-grey-light;
      color: $color-black;
    }

    &:hover,
    &:focus,
    &:not(:disabled):not(.disabled):active,
    .show > &.dropdown-toggle {
      border: solid 1px $color-button-secondary-border-hover;
      background: $color-white;
      color: $color-button-secondary-border-hover;
    }
  }

  &-tertiary {
    border: solid 1px $color-button-tertiary-border;

    &:hover,
    &:focus,
    &:not(:disabled):not(.disabled):active,
    .show > &.dropdown-toggle {
      border: solid 1px $color-button-tertiary-border-hover;
    }

    &:disabled {
      &,
      &:hover {
        background: $color-grey-light;
        border: 1px solid $color-grey-light;
        color: $color-black;
      }
    }
  }

  &-none {
    background: transparent;
    color: $color-black;
    font-size: 15px;
    margin: 0;
  }

  &-blank {
    color: $color-black;
    border: solid 1px $color-grey-light;
    background-color: $color-white;

    &:hover,
    &:focus,
    &:not(:disabled):not(.disabled):active,
    .show > &.dropdown-toggle {
      border: solid 1px $color-grey;
      background-color: $color-grey-light;
    }
  }

  &-languages {
    background: transparent;
    color: $color-white;
    font-size: 16px;
    margin: 0;
    padding-left: 0;

    @media ($under-screen-small) {
      margin-top: 20px;
      font-size: 18px;
      font-weight: 600;
    }

    &:hover {
      color: $color-white;

      span {
        text-decoration: underline;
      }
    }
  }

  &-facebook {
    color: $color-text-white;
    background-color: $color-button-facebook;
    border: solid 2px $color-button-facebook;

    &:hover {
      cursor: pointer;
      background-color: $color-button-facebook-hover;
      border: solid 2px $color-button-facebook-hover;
    }
  }

  &-google {
    color: $color-text-white;
    background-color: $color-button-google;
    border: solid 2px $color-button-google;

    &:hover {
      cursor: pointer;
      background-color: $color-button-google-hover;
      border: solid 2px $color-button-google-hover;
    }
  }

  &--transitional {
    transition: all 0.25s ease;

    .icon-right-open {
      opacity: 0;
      position: absolute;
      padding-left: 30px;
      font-size: 20px;
      transition: all 0.3s ease;
    }

    &:hover {
      cursor: pointer;
      padding-right: 30px;

      .icon-right-open {
        opacity: 1;
        position: absolute;
        padding-left: 5px;
        padding-right: 0;
        font-size: 20px;
      }
    }
  }

  &-lg-wide {
    padding-top: 14px;
    padding-bottom: 14px;
    @include text;
    width: 230px;
    margin-left: 0;
    margin-right: 0;
    color: $color-white;
  }

  &-sm {
    padding: 5px 10px;
  }

  &-lg {
    padding: 15px 20px;
  }

  &--full-width {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  &__content-with-img {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 8px;
    }
  }

  &-split {
    width: 48.5%;
    display: inline-block;
    @media ($under-screen-small) {
      width: 48%;
    }
  }
}

// caret
.dropdown-toggle::after {
  content: '\f004'; // icon-down-open
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  font-size: 1em;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  border: 0;
  margin: 0 0 0 5px;
  vertical-align: 0;
}

input,
textarea,
button {
  &:focus {
    outline: none;
  }
}
