.community-page-container {
  padding: 15px;
  margin: 20px auto 120px;
  max-width: 1200px;
  min-height: 300px;

  @media ($under-screen-xlarge) and ($over-screen-small) {
    margin-left: 20px;
  }

  @media ($under-screen-small) {
    padding: 0;
    margin-bottom: 20px;
  }
}

.community-page-container.full-width {
  @media ($under-screen-xlarge) and ($over-screen-small) {
    margin-right: 20px;
  }

  @media ($under-screen-small) {
    padding: 15px;
  }
}

.back-to-top {
  position: fixed;
  right: 13px;
  bottom: 13px;
  padding: 7px 12px;
  z-index: 1049; // Below the modal
  background: rgba(220, 220, 220, 1);
  border-radius: 2px;
  border: 0;
  font-size: inherit;
  cursor: pointer;
}
